import { Component, OnInit } from '@angular/core';
import { RestService } from 'src/app/services/rest.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-resetpw',
  templateUrl: './resetpw.component.html',
  styleUrls: ['./resetpw.component.scss'],
})
export class ResetpwComponent implements OnInit {
  code: any;
  password: any;
  email: any;
  constructor(private rest: RestService, private route: Router) {}

  ngOnInit() {
    this.email = localStorage.getItem('email');
  }

  submit() {
    let obj = {
      reset_token: this.code,
      password: this.password,
      email: this.email,
    };
    this.rest.resetPw(obj).subscribe((res: any) => {
      this.rest.successToast('Password Changed');
      this.route.navigateByUrl('/login');
    });
  }
}
