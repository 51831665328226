<!-- Banner section start -->
<section class="banner banarhome">
  <div class="container-fluid">
    <div class="row align-items-center">
      <div class="col-md-6 order-0 order-md-1">
        <!-- <div class="ban-img">
          <img src="assets/images/homepanar.jpeg" alt="">
        </div> -->
      </div>
      <!-- <div class="col-md-6 order-1 order-md-0">
        <div class="banner-content">
          <span class="tagline">ultimate collection</span>
          <h1>Let Nature Take Care Of You</h1>
          <a routerLink="/shop" class="btn-default">shop now</a>
        </div>
      </div> -->
    </div>
  </div>
</section>

<!-- Banner section end -->

<!-- Categories -->
<section class="feat-product">
  <div class="container">
    <div class="row">
      <div class="col">
        <div class="sec-heading">
          <h3 class="sec-title">All Categories</h3>
        </div>
      </div>
    </div>

    <swiper [config]="config">
      <ng-template swiperSlide *ngFor="let item of category">
        <div class="product-item">
          <figure class="product-thumb">
            <img class="imageCat" [src]="item?.image?.image_url" alt="" />
            <div class="action-links">
              <button
                [routerLink]="['/Products', item?.id]"
                class="btn shopNow"
              >
                Shop Now
              </button>
            </div>
          </figure>
          <div class="product-content">
            <h5 class="product-name">
              <a>{{ item?.name }}</a>
            </h5>
          </div>
        </div>
      </ng-template>
    </swiper>

    <!-- <div class="imageContainer">
      <div class="row">
        <div class="colum1 col-5">
          <div class="cat" *ngFor="let item of category ; let i = index">
              <img class="img-fulid" *ngIf="i < 3"
              (error)="item.image.image_url = 'assets/images/rk.jpeg'"
              [src]="item.image.image_url">
          </div>
        </div>
        <div class="colum2 col-7">
            <div [ngClass]="{'imagefive': i === 5 }" class="cat" *ngFor="let item of category ; let i = index">
                <img class="img-fulid" *ngIf="i > 3"
                 (error)="item.image.image_url = 'assets/images/rk.jpeg'"
                [src]="item.image.image_url">
            </div>
          </div>
      </div>
    </div> -->
  </div>
</section>
<!-- Categories end -->

<!-- Banner 02 section start -->
<section class="banner02 mt-5">
  <!-- <div class="container-fluid">
    <div class="row align-items-center">
      <div class="col-md-6">
        <div class="ban-img">
          <img src="assets/images/ban50.jpeg" alt="">
        </div>
      </div>
      <div class="col-md-6">
        <div class="banner-content">
          <h1>50% off</h1>
          <h4>all women’s collection</h4>
          <a routerLink="/shop" class="btn-default">shop now</a>
        </div>
      </div>
    </div>
  </div> -->
</section>
<!-- Banner 02 section end -->

<!-- Features section start -->
<section class="features">
  <div class="container">
    <div class="row">
      <!-- <div class="col-md-3 col-6">
        <div class="featureBox">
          <img
            src="assets/images/feature-icons/2.png"
            alt="Delivery"
            class="feat-icon"
          />
          <h5>Free delivery</h5>
          <p>Free delivery on your first order</p>
        </div>
      </div> -->
      <div class="col-md-5 col-10">
        <div class="featureBox">
          <img
            src="assets/images/feature-icons/3.png"
            alt="Support"
            class="feat-icon"
          />
          <h5>We're here for you</h5>
          <p>Reach us using our facebook chat</p>
        </div>
      </div>
      <div class="col-md-5 col-10">
        <div class="featureBox">
          <img
            src="assets/images/feature-icons/4.png"
            alt="Secure payment"
            class="feat-icon"
          />
          <h5>Cant' find your product ?</h5>
          <p>
            Request your product here with an image, a URL and we'll get it for
            you
          </p>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- Features section end -->

<!-- our prands -->

<section>
  <div class="container">
    <div class="sec-heading">
      <h3 class="sec-title">Our Brands</h3>
    </div>
    <div class="text-center prands">
      <img src="assets/images/prands.png" />
    </div>
  </div>
</section>

<!--Trending Products start -->
<section class="trending-products">
  <div class="container">
    <div class="row">
      <div class="col">
        <div class="sec-heading">
          <h3 class="sec-title lineHight">Trending products</h3>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-3 col-6" *ngFor="let item of products">
        <div class="product-item">
          <figure class="product-thumb">
            <img
              [src]="
                item?.images[0]?.image_url
                  ? item?.images[0]?.image_url
                  : 'assets/images/rk.jpeg'
              "
              alt=""
            />
            <div class="action-links">
              <a
                href="#"
                class="quick-view icon"
                [routerLink]="['/products_details', item.id]"
                ><i class="ti-eye"></i
              ></a>
              <a href="#" class="add-cart icon"
                ><i class="ti-shopping-cart"></i
              ></a>
            </div>
          </figure>
        </div>
        <div class="product-content">
          <h5 class="product-name text-truncate">
            <a>{{ item.name }}</a>
          </h5>
          <!-- <div class="ratings">
            <a href="#"><i class="ti-star"></i></a>
            <a href="#"><i class="ti-star"></i></a>
            <a href="#"><i class="ti-star"></i></a>
            <a href="#"><i class="ti-star"></i></a>
            <a href="#"><i class="ti-star"></i></a>
          </div> -->
          <p class="price">LE.{{ item.price }}</p>
        </div>
      </div>
    </div>
  </div>
</section>
<!--Trending Products end -->

<!--packages -->
<section *ngIf="ifPackages" class="trending-products">
  <div class="container">
    <div class="row">
      <div class="col">
        <div class="sec-heading">
          <h3 class="sec-title lineHight">Trending Packages</h3>
        </div>
      </div>
    </div>
    <div class="row">
      <div
        title="{{ item?.name }}"
        (click)="pack_details(item.id)"
        class="col-6 col-md-4 pack"
        *ngFor="let item of allPackages"
      >
        <div class="cardStyle">
          <img [src]="item?.image" />
        </div>
        <div class="packInfo" style="text-align: center">
          <h2>{{ item?.name }}</h2>
          <p class="price">{{ item?.total_price }} <strong>LE</strong></p>
        </div>
      </div>
    </div>
  </div>
</section>
<!--packages -->
