<section>
  <div>
    <h3>Your address</h3>
    <hr />
    <p>City : {{ city }}</p>
    <p>Governorate : {{ goverment }}</p>
    <p>Address Details : {{ addres }}</p>
  </div>
  <hr />
  <div>
    <button class="btn confirm" (click)="closeDialog('true')">Confirm</button>
    <button class="btn edit" (click)="closeDialog('false')">
      Edit Your Address
    </button>
  </div>
</section>
