<section class="banar"></section>
<section>
  <div class="container">
    <div class="row">
      <div
        class="col-md-3 col-6"
        *ngFor="let item of all_category; let i = index"
      >
        <div class="card_style" (click)="cat_details(item)">
          <img [src]="item.image.image_url" />
        </div>
        <div style="text-align: center">
          <h1>{{ item.name | uppercase }}</h1>
        </div>
      </div>
    </div>
  </div>
</section>
