import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UserProfileComponent } from '../user-profile/user-profile.component';
import { RestService } from 'src/app/services/rest.service';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';

@Component({
  selector: 'app-edit-order',
  templateUrl: './edit-order.component.html',
  styleUrls: ['./edit-order.component.css'],
})
export class EditOrderComponent implements OnInit {
  orderProductsForEditing: any;
  currentProductForm: FormGroup | any;
  orderDetails: any;
  orderAddressForEditing: any;
  clientAddressIdSelected: any;
  orderFees: any;
  clientIdForAddress: any;
  filteredProductDataWithIdAndAmount: any;
  currentProductAmountControls: FormControl[] = [];
  order_arr: any = [];
  clientName: any;
  packagesArr: any = [];
  product_arr: any;
  cancelButtonStatus = false;
  packImageStatus = true;
  catName: any;
  packageName: any;
  ifPackage = false;
  package_arr: any;
  packageID: any;
  arrayChoicePackage: any = [];
  arrayOfPackages: any = [];
  packageQuantity: any;
  orderPackagesForEditing: any;
  filteredPackagesDataWithIdAndAmount: any;
  currentPackageAmountControls: FormControl[] = [];
  status_prod = false;
  ifProd = false;
  prod_arr: any;
  cat_arr: any;
  prodID: any;
  prodName: any;
  Quantity: any;
  arrayChoiceProduct: any = [];
  arrayOfProductPackages: any = [];
  orderPhoneNumber: any;
  clientAddress: FormGroup | any;
  showAddingNewAddressForm: boolean = false;
  showChangingAddress: boolean = false;
  clientAddressForUpdatingAddress: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<UserProfileComponent>,
    private rest: RestService
  ) {}

  ngOnInit() {
    this.getData();
    this.getDataForEditModel();
    this.phoneNumberForm();
  }

  getData() {
    this.rest
      .orderDetails(this.data?.token, this.data?.orderId)
      .subscribe((res: any) => {
        this.orderDetails = res;
        this.orderPhoneNumber = this.orderDetails?.phone_number;

        this.phoneNoForm.patchValue({
          phoneNumber: this.orderPhoneNumber,
        });

        this.orderProductsForEditing = res?.OrdersProducts;
        this.orderPackagesForEditing = res?.packages;
        this.orderAddressForEditing = res?.address;
        this.clientAddressIdSelected = this.orderAddressForEditing?.id;
        this.orderFees = this.orderDetails?.fees;
        this.clientIdForAddress = res?.client?.id;

        //Amount For products
        this.filteredProductDataWithIdAndAmount =
          this.orderProductsForEditing.map((item: any) => {
            return {
              id: item?.ordersproducts?.id,
              quantity: item?.ammount,
            };
          });

        this.orderProductsForEditing.forEach((item: any, index: number) => {
          const control = new FormControl(item.ammount);
          this.currentProductForm.addControl(
            'currentProductAmount' + index,
            control
          );
          this.currentProductAmountControls.push(control);
        });
        //Amount For products

        //Amount For packages
        this.filteredPackagesDataWithIdAndAmount =
          this.orderPackagesForEditing.map((item: any) => {
            return {
              id: item?.orderspackages?.id,
              amount: item?.ammount,
            };
          });

        this.orderPackagesForEditing.forEach((item: any, index: number) => {
          const control = new FormControl(item.ammount);
          this.currentProductForm.addControl(
            'currentPackageAmount' + index,
            control
          );
          this.currentPackageAmountControls.push(control);
        });
        //Amount For packages

        this.order_arr.push(res);
        this.clientName = res?.client?.email;
        this.packagesArr = res?.packages;
        this.product_arr = res?.OrdersProducts;
        if (res.paid == true) {
          this.cancelButtonStatus = true;
        }
      });

    this.getCurrentOrderForm();
  }

  changeProductAmount(productId: any, productAmount: number) {
    for (let i = 0; i < this.filteredProductDataWithIdAndAmount?.length; i++) {
      if (this.filteredProductDataWithIdAndAmount[i].id === productId) {
        if (productAmount === 0) {
          this.filteredProductDataWithIdAndAmount.splice(i, 1);
        } else {
          this.filteredProductDataWithIdAndAmount[i].quantity = productAmount;
        }
        break;
      }
    }
  }

  changePackageAmount(packageId: any, packageAmount: number) {
    for (let i = 0; i < this.filteredPackagesDataWithIdAndAmount?.length; i++) {
      if (this.filteredPackagesDataWithIdAndAmount[i].id === packageId) {
        if (packageAmount === 0) {
          this.filteredPackagesDataWithIdAndAmount.splice(i, 1);
        } else {
          this.filteredPackagesDataWithIdAndAmount[i].amount = packageAmount;
        }
        break;
      }
    }
  }

  getCurrentOrderForm() {
    this.currentProductForm = new FormGroup({
      currentProductAmount: new FormControl(null),
      currentPackageAmount: new FormControl(null),
    });
  }

  getCatId(item: any) {
    // this.rest.showSpinner();
    this.catName = item.name;
    this.status_prod = false;
    this.rest.catDetails(1, 10, item?.id).subscribe((res: any) => {
      // this.rest.hideSpinner();
      this.status_prod = true;
      if (res?.products?.length != 0) {
        this.ifProd = true;
        this.prod_arr = res?.products;
      } else {
        this.ifProd = false;
      }
    });
  }

  selectPackageFromList(item: any) {
    this.packageName = item?.name;
    this.packageID = item?.id;
    this.ifPackage = true;
  }

  choseProd(item: any) {
    this.prodID = item?.id;
    this.prodName = item?.name;
  }

  getDataForEditModel() {
    // this.rest.showSpinner();
    // Get category list
    this.rest.listCatOnly(this.data?.token).subscribe((res: any) => {
      this.cat_arr = res;
      // this.rest.hideSpinner();
    });

    // Get package list
    this.rest.allPackage().subscribe((res: any) => {
      this.package_arr = res;
    });
  }

  add() {
    this.arrayChoiceProduct.push({
      prodName: this.prodName,
      amount: this.Quantity,
    });
    this.arrayOfProductPackages.push({
      id: this.prodID,
      quantity: this.Quantity,
    });

    this.ifProd = false;
    this.status_prod = false;
    this.catName = '';
    this.prodName = '';
    this.Quantity = '';
  }

  addPackage() {
    this.arrayChoicePackage.push({
      packageName: this.packageName,
      amount: this.packageQuantity,
    });
    this.arrayOfPackages.push({
      id: this.packageID,
      amount: this.packageQuantity,
    });

    this.ifPackage = false;
    this.packageName = '';
    this.packageName = '';
    this.packageQuantity = '';
  }

  changeAddress() {
    this.getClientAddress();
    this.showChangingAddress = true;
  }

  getClientAddress() {
    // this.rest.showSpinner();

    this.rest
      .getUserAddress(this.data.token, this.clientIdForAddress)
      .subscribe((res) => {
        // this.rest.hideSpinner();

        this.clientAddressForUpdatingAddress = res;
      });
  }

  selectAddress(addressId: any) {
    this.clientAddressIdSelected = addressId;
  }

  addNewAddress() {
    this.addNewAddressForm();
    this.showAddingNewAddressForm = true;
    this.showChangingAddress = false;
  }

  addNewAddressForm() {
    this.clientAddress = new FormGroup({
      address: new FormControl('', [Validators.required]),
      governorate: new FormControl('', [Validators.required]),
      city: new FormControl('', [Validators.required]),
      client_id: new FormControl(this.clientIdForAddress),
    });
  }

  saveAddress() {
    this.showAddingNewAddressForm = false;
    this.showChangingAddress = true;
    this.rest
      .addNewAddress(this.clientAddress.value, this.data.token)
      .subscribe(() => {
        this.rest.successToast('Address added successfully');
      });

    setTimeout(() => {
      this.getClientAddress();
    }, 100);
  }

  backToClientAddressList() {
    this.showAddingNewAddressForm = false;
    this.showChangingAddress = true;
  }

  closePrompt() {
    this.showChangingAddress = false;
    this.showAddingNewAddressForm = false;
    this.arrayChoiceProduct = [];
    this.ifProd = false;
    this.status_prod = false;
    this.catName = null;
  }

  //Phone number Form
  phoneNoForm: FormGroup | any;
  phoneNumberForm() {
    this.phoneNoForm = new FormGroup({
      phoneNumber: new FormControl('', [
        Validators.required,
        this.phoneNumberValidator,
      ]),
    });
  }
  //Phone number Form

  save() {
    // this.rest.showSpinner();
    let obj = {
      id: this.data?.orderId,
      address_id: this.clientAddressIdSelected,
      cashed: this.orderDetails?.cashed,
      products: this.arrayOfProductPackages?.length
        ? this.arrayOfProductPackages.concat(
            this.filteredProductDataWithIdAndAmount
          )
        : this.filteredProductDataWithIdAndAmount,

      packages: this.arrayOfPackages?.length
        ? this.arrayOfPackages.concat(this.filteredPackagesDataWithIdAndAmount)
        : this.filteredPackagesDataWithIdAndAmount,

      fees: this.orderFees,
      phone_number: this.phoneNoForm.get('phoneNumber').value,
    };
    this.rest.editOrder(this.data.token, obj).subscribe(
      (res: any) => {
        // this.rest.hideSpinner();
        this.order_arr = [];
        this.closePrompt();
        this.dialogRef.close(res.status);
      },
      (err: any) => {
        // this.rest.hideSpinner();
      }
    );
  }

  phoneNumberValidator(control: AbstractControl): ValidationErrors | null {
    const phonePattern = /^[0-9]{11}$/;
    const value = control.value;

    if (!value) {
      return null; // Allow empty value
    }

    if (phonePattern.test(value)) {
      return null; // Validation passes
    } else {
      return { invalidEmailOrPhone: true }; // Validation fails
    }
  }
}
