<section class="mainSection">
  <div class="middle">
    <h1 class="title">Choose Payment</h1>
    <label>
      <input type="radio" (change)="radioValue(true)" name="radio" checked />
      <div class="front-end box">
        <span>Cash On Delivery</span>
      </div>
    </label>

    <label>
      <input type="radio" (change)="radioValue(false)" name="radio" />
      <div class="back-end box">
        <span>Vodafone Cash</span>
      </div>
    </label>
  </div>
  <div class="text-center">
    <button class="btn confirm" (click)="openAddressInfo()">Confirm</button>
  </div>
</section>
