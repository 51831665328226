<section>
    <div class="container">
        <h1>Search on {{textInput}}</h1>
        <div class="main">
            <div class="row">
                <div class="col-6 col-md-3" *ngFor="let item of products">
                    <div class="product-item" (click)="productDetails(item)">
                        <figure class="product-thumb">
                            <img [src]="item.images[0].image_url" alt="">
                            <!-- <div class="action-links">
                                <button class="btn btn-cart">Add To Cart</button>
                            </div> -->
                        </figure>
                        <div class="product-content">
                            <h5 class="product-name text-truncate"><a>{{item.name}}</a></h5>
                            <div class="ratings">
                                <a *ngFor="let rate of item.rating_arr"><i class="ti-star"></i></a>
                            </div>
                            <div *ngIf="item.rating_arr.length == 0" class="ratings">
                                    <a style="color: #000"><i class="ti-star"></i></a>
                                    <a style="color: #000"><i class="ti-star"></i></a>
                                    <a style="color: #000"><i class="ti-star"></i></a>
                                    <a style="color: #000"><i class="ti-star"></i></a>
                                    <a style="color: #000"><i class="ti-star"></i></a>
                                </div>
                            <p class="price">LE.{{item.price}}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <h2>{{product_massge}}</h2>
            </div>
        </div>
    </div>
</section>