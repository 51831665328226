import { Component, OnInit } from '@angular/core';
import { RestService } from 'src/app/services/rest.service';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-search-result',
  templateUrl: './search-result.component.html',
  styleUrls: ['./search-result.component.scss']
})
export class SearchResultComponent implements OnInit {

  textInput:any
  products:any
  rating:any
  product_massge:any
  constructor(private rest : RestService ,
    private routing : Router,
     private _sanitizer: DomSanitizer){}

  ngOnInit(){
   this.textInput = localStorage.getItem('searchText')
   this.getData()
  }

  getData(){
    this.rest.search(this.textInput).subscribe((res:any) => {
      if(res){
        res.forEach((element :any) => {
          this.rating = []
          for(let i = 0 ; i < element.rating ; i++){
            this.rating.push("1")
          }
          element.rating_arr =  this.rating
          if(element.images.length != 0){
            element.images[0].image_url = `${environment.apiUrl}/images/?id=${element.images[0].image_url}`
          }else {
            element.images.push({
              image:"assets/images/rk.jpeg"
            })
          }
        });
        this.products = res
        this.product_massge = ""
      }else {
        this.product_massge = "No Product Found"
      }

    })
  }
  productDetails(value:any){
    this.routing.navigate(['/products_details', value.id]);
  }


}
