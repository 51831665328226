<header>
  <div class="container">
    <div class="welcome">
      <h3>
        Hello <strong>{{ userName | titlecase }} </strong> !
      </h3>
      <button class="btn editButton" (click)="openDialog()">
        Edit my account
      </button>
    </div>
  </div>
</header>
<section>
  <div class="container">
    <mat-tab-group>
      <!-- <mat-tab label="Dashboard">
        <div class="main">
          <h1>Recent Order</h1>
          <div *ngFor="let item of orders; let i = index">
            <div class="cardStyle" *ngIf="i == orders.length - 1">
              <p class="title">Order Sent</p>
              <div class="row">
                <div class="col-12 col-md-4">
                  <div class="d-f">
                    <div>
                      <strong class="suptitle">Order Number</strong>
                      <p>#{{ item.id }}</p>
                    </div>
                    <div>
                      <strong class="suptitle">Date Order</strong>
                      <p>{{ item.issue_date | date : "yyyy/MM/dd" }}</p>
                    </div>
                  </div>
                  <div class="d-f">
                    <div>
                      <strong class="suptitle">Payment</strong>
                      <p *ngIf="item.cashed == true">Cash On Delivery</p>
                      <p *ngIf="item.cashed == false">Vodafone Cash</p>
                    </div>
                    <div>
                      <strong class="suptitle">Total</strong>
                      <p>{{ item.total_price }} LE</p>
                    </div>
                  </div>
                  <div class="d-f">
                    <div>
                      <strong class="suptitle">Paid</strong>
                      <p *ngIf="item.paid == null">No</p>
                      <p *ngIf="item.paid != null">Yes</p>
                    </div>
                    <div>
                      <strong class="suptitle">Expected arrival</strong>
                      <p>{{ item.expected_arrival | date : "yyyy/MM/dd" }}</p>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-5 offset-md-1">
                  <div>
                    <strong>Total Items ({{ item.OrdersProducts.length }})</strong>
                    <div>
                      <table>
                        <thead>
                          <tr>
                            <th>Image</th>
                            <th>Name</th>
                            <th>Quantity</th>
                            <th>Price</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let product of item?.OrdersProducts">
                            <td>
                              <img
                                [src]="product.ordersproducts.images[0].image"
                              />
                            </td>
                            <td>{{ product?.ordersproducts?.name }}</td>
                            <td>{{ product?.ammount }}</td>
                            <td class="itemPrice">{{ product?.ordersproducts?.price }} LE</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <button
                class="btn btn-primary cancel"
                (click)="openDialogCancel(item?.id)"
              >
                Cancel order
              </button>
            </div>
          </div>
        </div>
      </mat-tab> -->
      <mat-tab label="Personal Information">
        <section class="tab2">
          <div>
            <h1>Personal Information</h1>
            <h3>{{ fullName }}</h3>
            <div class="d-f-tab2">
              <div>
                <p class="suptitle">Place</p>
                <p>{{ city }}</p>
              </div>
              <div>
                <p class="suptitle">Mobile Number</p>
                <p>{{ mobileNumber }}</p>
              </div>
            </div>
            <button class="btn editInfo" (click)="openDialog()">
              Edit Personal Info
            </button>
          </div>
          <hr />
          <div>
            <h1>Login Info</h1>
            <div class="d-f-tab2">
              <div>
                <p class="suptitle">E-mail</p>
                <p>{{ email }}</p>
              </div>
              <div>
                <p class="suptitle">User Name</p>
                <p>{{ userName }}</p>
              </div>
            </div>
          </div>
          <hr />
          <div style="margin-bottom: 10px">
            <h1>More Information</h1>
            <div class="d-f-tab2">
              <div>
                <p class="suptitle">Best surprise</p>
                <p>{{ best_surprise }}</p>
              </div>
              <div>
                <p class="suptitle">Favorite colour</p>
                <p>{{ favColor }}</p>
              </div>
            </div>
            <div class="d-f-tab2">
              <div>
                <p class="suptitle">Marital status</p>
                <p>{{ marital_status }}</p>
              </div>
              <div>
                <p class="suptitle">Ultimate goals</p>
                <p>{{ Ultimate_goals }}</p>
              </div>
            </div>
            <button class="btn editInfo" (click)="editMoreInfo()">
              Edit More Info
            </button>
          </div>
        </section>
      </mat-tab>
      <mat-tab label="My Orders">
        <div class="main">
          <h1>History Order</h1>
          <div class="cardStyle" *ngFor="let item of orders">
            <p class="title">Order Sent</p>
            <div class="row">
              <div class="col-12 col-md-4">
                <div class="d-f">
                  <div>
                    <strong class="suptitle">Order Number</strong>
                    <p>#{{ item.id }}</p>
                  </div>
                  <div>
                    <strong class="suptitle">Date Order</strong>
                    <p>{{ item.issue_date | date : "yyyy/MM/dd" }}</p>
                  </div>
                </div>
                <div class="d-f">
                  <div>
                    <strong class="suptitle">Payment</strong>
                    <p *ngIf="item.cashed == true">Cash On Delivery</p>
                    <p *ngIf="item.cashed == false">Vodafone Cash</p>
                  </div>
                  <div>
                    <strong class="suptitle">Total</strong>
                    <p>{{ item.total_price }} LE</p>
                  </div>
                </div>
                <div class="d-f">
                  <div>
                    <strong class="suptitle">Paid</strong>
                    <p *ngIf="item.paid == null">No</p>
                    <p *ngIf="item.paid != null">Yes</p>
                  </div>
                  <div>
                    <strong class="suptitle">Expected arrival</strong>
                    <p>{{ item.expected_arrival | date : "yyyy/MM/dd" }}</p>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-5 offset-md-1">
                <div>
                  <strong
                    >Total Items ({{
                      item.OrdersProducts.length + item.packages.length
                    }})</strong
                  >
                  <div>
                    <table>
                      <thead *ngIf="item?.OrdersProducts.length">
                        <tr>
                          <th>Image</th>
                          <th>Product name</th>
                          <th>Quantity</th>
                          <th>Price</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let product of item?.OrdersProducts">
                          <td>
                            <img
                              [src]="product.ordersproducts.images[0].image"
                            />
                          </td>
                          <td>{{ product?.ordersproducts?.name }}</td>
                          <td>{{ product?.ammount }}</td>
                          <td class="itemPrice">
                            {{ product?.ordersproducts?.price }} LE
                          </td>
                        </tr>
                      </tbody>

                      <thead *ngIf="item?.packages.length">
                        <tr>
                          <th colspan="2">Package name</th>
                          <th>No of products</th>
                          <th>Price</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let product of item?.packages">
                          <td colspan="2">{{ product?.name }}</td>
                          <td>{{ product?.quantities }}</td>
                          <td class="itemPrice">
                            {{ product?.total_price }} LE
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <button
              class="btn btn-primary cancel mr-2"
              (click)="openDialogCancel(item.id)"
            >
              Cancel order
            </button>
            <button
              class="btn btn-primary editOrder"
              (click)="openDialogEdit(item?.id)"
            >
              Edit order
            </button>
          </div>

          <!-- requests -->
        </div>
      </mat-tab>

      <mat-tab label="Product Requests">
        <div class="cardStyle" *ngFor="let item of order_request">
          <p class="title">Order Request</p>
          <div class="row">
            <div class="col-12 col-md-4">
              <div class="d-f">
                <div>
                  <p class="suptitle">Order Number</p>
                  <p>#{{ item.id }}</p>
                </div>
                <div>
                  <p class="suptitle">Date Order</p>
                  <p>{{ item.requested_date | date : "yyyy/MM/dd" }}</p>
                </div>
              </div>
              <div class="">
                <div>
                  <p class="suptitle">Name</p>
                  <p>{{ item.name }}</p>
                </div>
                <div>
                  <p class="suptitle">Url</p>
                  <p class="text-truncate">
                    <a target="_blank" href="{{ item.URL }}">{{ item.URL }}</a>
                  </p>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-5 offset-md-1">
              <div>
                <div *ngFor="let img of item?.images">
                  <img [src]="img?.image" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <p *ngIf="order_request.length == 0">No Product Found</p>
      </mat-tab>
    </mat-tab-group>
  </div>
</section>
