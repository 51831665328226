import { Component, OnInit } from '@angular/core';
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { HeaderComponent } from '../header/header.component';

@Component({
  selector: 'app-request-product',
  templateUrl: './request-product.component.html',
  styleUrls: ['./request-product.component.scss'],
})
export class RequestProductComponent implements OnInit {
  clientAddress: FormGroup | any;
  fileUpload: any;

  constructor(
    public dialogRef: MatDialogRef<HeaderComponent>,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit() {
    // this.clientAddress = new FormGroup({
    //   name: new FormControl(""),
    //   image: new FormControl(""),
    //   url: new FormControl(""),
    // })
    this.clientAddress = this.formBuilder.group({
      name: ['', Validators.required],
      image: [''],
      url: [''],
    });
  }

  onNoClick(): void {
    if (this.clientAddress.valid) {
      this.clientAddress.value.image = this.fileUpload;
      this.dialogRef.close(this.clientAddress.value);
    }
  }

  onFileUpload(event: any) {
    if (event.target.files.length > 0) {
      this.fileUpload = event.target.files[0];
    }
  }
}
