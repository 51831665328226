import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { RestService } from 'src/app/services/rest.service';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { UpdateMoreInfoComponent } from '../update-more-info/update-more-info.component';

@Component({
  selector: 'app-singup',
  templateUrl: './singup.component.html',
  styleUrls: ['./singup.component.scss'],
})
export class SingupComponent implements OnInit {
  constructor(
    private rest: RestService,
    public dialog: MatDialog,
    private route: Router
  ) {}

  formRegister: FormGroup | any;
  formStatus = false;
  ngOnInit() {
    this.formRegister = new FormGroup({
      email: new FormControl('', [
        Validators.required,
        Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'),
      ]),
      phone_number: new FormControl('', [Validators.required]),
      password: new FormControl('', [
        Validators.required,
        Validators.minLength(8),
      ]),
      username: new FormControl('', Validators.required),
    });
  }

  submit() {
    if (!this.formRegister.valid) {
      this.formStatus = true;
    } else {
      this.formStatus = false;
      let form = this.formRegister.value;
      this.rest.signUp(form).subscribe(
        (res: any) => {
          localStorage.setItem('token', res.access_token);
          localStorage.setItem('clientID', res.client.id);
          localStorage.setItem('phone_number', res.client.phone_number);
          localStorage.setItem('user', res.client.email);
          this.rest.SendDataIfLogin('true');
          this.rest.successToast('Register Successfully');
          this.rest.setLoginStatus(true);
          this.editMoreInfo(res.client.id, res.access_token);
        },
        (err: any) => {
          if (err.error.username) {
            this.rest.errorToaster('User name' + ' ' + err.error.username);
          } else {
            this.rest.errorToaster(err.error.message);
          }
        }
      );
    }
  }
  editMoreInfo(id: any, token: any) {
    const dialogRef = this.dialog.open(UpdateMoreInfoComponent, {
      width: '500px',
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result == 'skip') {
        this.route.navigateByUrl('/home');
      } else if (result) {
        result.id = id;
        this.rest.finishClientData(result, token).subscribe((res: any) => {
          this.route.navigateByUrl('/home');
        });
      } else {
        this.route.navigateByUrl('/home');
      }
    });
  }
}
