import { Component, OnInit } from '@angular/core';

import SwiperCore, {
  Autoplay,
  Keyboard,
  Pagination,
  Scrollbar,
  Zoom,
  SwiperOptions,
} from 'swiper';
import { RestService } from 'src/app/services/rest.service';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
SwiperCore.use([Autoplay, Keyboard, Pagination, Scrollbar, Zoom]);
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  config: SwiperOptions = {
    breakpoints: {
      300: {
        slidesPerView: 2,
      },
      640: {
        slidesPerView: 2,
      },
      768: {
        slidesPerView: 2,
      },
      1024: {
        slidesPerView: 4,
      },
    },
    spaceBetween: 20,
    pagination: {
      clickable: true,
      dynamicBullets: true,
    },
  };
  category: any;
  products: any;
  allPackages: any;
  ifPackages = false;
  constructor(
    private rest: RestService,
    private route: Router,
    private _sanitizer: DomSanitizer
  ) {}

  ngOnInit() {
    this.getData();
  }

  getData() {
    this.rest.homePage().subscribe((res: any) => {
      this.category = res.categories;
      this.products = res.products;
      this.allPackages = res.packages;

      if (this.allPackages.length != 0) {
        this.ifPackages = true;
      } else {
        this.ifPackages = false;
      }

      this.category.forEach((element: any) => {
        if (element.image != null) {
          element.image.image_url = `${environment.apiUrl}/images/?id=${element.image.image_url}`;
        } else {
          element.image = {
            image_url: 'assets/images/rk.jpeg',
          };
        }
      });

      this.products.forEach((element: any) => {
        if (element.images.length != 0) {
          element.images[0].image_url = `${environment.apiUrl}/images/?id=${element.images[0].image_url}`;
          // element.images[0].image = this._sanitizer.bypassSecurityTrustUrl(`data:image/png;base64,`+ element.images[0].image);
        } else {
          element.image = {
            image_url: 'assets/images/rk.jpeg',
          };
        }
      });

      this.allPackages.forEach((element: any) => {
        if (element.image != null) {
          element.image = `${environment.apiUrl}/images/?id=${element.image}`;
        } else {
          element.image = 'assets/images/rk.jpeg';
        }
      });
    });
  }

  pack_details(id: any) {
    this.route.navigate(['/packges_details', id]);
  }
}
