<section>
  <h3>
    It seems you didn't provide your address, it's Ok we are here for you.
    first, please fill the data below,
  </h3>
  <hr />
  <div>
    <div *ngIf="!showAddingNewAddressForm">
      <div *ngFor="let address of userAddresses" class="addressesDiv">
        <input
          class="mr-2"
          type="radio"
          id="radio_{{ address.id }}"
          [value]="address?.id"
          name="address"
          (change)="selectAddress(address?.id)"
        />
        <label for="radio_{{ address.id }}">
          <div class="d-flex align-items-center">
            <strong
              >{{ address?.city
              }}<small class="ml-2">{{ address?.governorate }}</small></strong
            >
          </div>
          <p>{{ address?.addresss }}</p></label
        ><br />
      </div>
    </div>

    <form [formGroup]="clientAddress" *ngIf="showAddingNewAddressForm">
      <div class="form-group">
        <label>City</label>
        <input formControlName="city" type="text" class="form-control" />
      </div>
      <div class="form-group">
        <label>Governorate</label>
        <input formControlName="governorate" type="text" class="form-control" />
      </div>
      <div class="form-group">
        <label>Address</label>
        <input formControlName="address" type="text" class="form-control" />
      </div>
    </form>

    <div class="d-flex justify-content-between addressActions">
      <button
        *ngIf="!showAddingNewAddressForm"
        (click)="addNewAddress()"
        class="btn w-100"
      >
        Add new address
      </button>

      <button
        [disabled]="!clientAddress.valid"
        *ngIf="showAddingNewAddressForm"
        (click)="saveAddress()"
        class="btn"
      >
        Save
      </button>
    </div>

    <div class="d-flex justify-content-between addressActions mt-3">
      <button
        (click)="onNoClick()"
        [disabled]="
          !addressIdSelected ||
          addressIdSelected == undefined ||
          showAddingNewAddressForm
        "
        class="btn confirmOrder w-100"
      >
        Confirm Order
      </button>
    </div>
  </div>
</section>
