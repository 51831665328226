<header class="header">
  <div class="top-header">
    <div class="container">
      <div class="row">
        <div class="col">
          <span>Phone: +01011419115</span>
          <span class="emailAddress">email: support@radwakhallaf.com</span>
        </div>
        <div class="col text-right">
          <div *ngIf="!ifLogin">
            <span style="cursor: pointer" routerLink="/login">Login</span>
            <span>/</span>
            <span style="cursor: pointer" routerLink="/sign_up">Register</span>
          </div>
          <!-- <div *ngIf="ifLogin">
                        <span>{{userEmail}}</span>
                        <span [matMenuTriggerFor]="menu" class="user-image"><img
                                src="assets/images/userlogin.png"></span>
                        <mat-menu #menu="matMenu" yPosition="below">
                            <button routerLink="/user_profile" mat-menu-item>
                                <mat-icon>person</mat-icon>
                                Profile
                            </button>
                            <button mat-menu-item (click)="logout()">
                                Logout
                                <mat-icon>logout</mat-icon>
                            </button>
                        </mat-menu>
                    </div> -->
        </div>
      </div>
    </div>
  </div>
  <div class="container">
    <nav class="navbar">
      <!-- Site logo -->
      <a routerLink="/" class="logo">
        <img src="assets/images/Logo ver 1.png" alt="" />
      </a>

      <a
        href="javascript:void(0);"
        [matMenuTriggerFor]="menus"
        id="mobile-menu-toggler"
      >
        <i class="ti-align-justify"></i>
      </a>

      <mat-menu #menus="matMenu" yPosition="below">
        <button
          routerLinkActive="activebutton"
          routerLink="/home"
          mat-menu-item
        >
          Home
        </button>
        <button
          routerLinkActive="activebutton"
          routerLink="/about"
          mat-menu-item
        >
          About
        </button>
        <button
          routerLinkActive="activebutton"
          routerLink="/categories"
          mat-menu-item
        >
          Categories
        </button>
        <button
          routerLinkActive="activebutton"
          routerLink="/packges"
          mat-menu-item
        >
          Packages
        </button>
        <button
          routerLinkActive="activebutton"
          routerLink="/shop"
          mat-menu-item
        >
          Shop
        </button>
        <button
          routerLinkActive="activebutton"
          routerLink="/blogs"
          mat-menu-item
        >
          blogs
        </button>
        <button
          *ngIf="showRequestProduct"
          (click)="openDialog()"
          style="cursor: pointer"
          mat-menu-item
        >
          Request Product
        </button>
      </mat-menu>
      <ul class="navbar-nav">
        <!-- <li class="current-menu-item has-menu-child">
                    <a href="#">Home</a>
                    <ul class="sub-menu">
                        <li><a href="home-01.html">Home 01</a></li>
                        <li><a href="home-02.html">Home 02</a></li>
                        <li><a href="home-03.html">Home 03</a></li>
                    </ul>
                </li> -->
        <li><a routerLinkActive="active" routerLink="/home">Home</a></li>
        <li><a routerLinkActive="active" routerLink="/about">About</a></li>
        <li>
          <a routerLinkActive="active" routerLink="/categories">Categories</a>
        </li>
        <li><a routerLinkActive="active" routerLink="/packges">Packages</a></li>
        <li><a routerLinkActive="active" routerLink="/shop">Shop</a></li>
        <li><a routerLinkActive="active" routerLink="/blogs">Blogs</a></li>
        <li>
          <a
            *ngIf="showRequestProduct"
            (click)="openDialog()"
            style="cursor: pointer"
            >Request Product</a
          >
        </li>
      </ul>

      <div class="d-inline-flex align-items-center">
        <div *ngIf="ifLogin">
          <span
            style="cursor: pointer"
            [matMenuTriggerFor]="menu"
            class="user-image icon"
          >
            <img src="assets/images/userlogin.png" />
          </span>
          <mat-menu #menu="matMenu" yPosition="below">
            <button routerLink="/user_profile" mat-menu-item>
              <mat-icon>person</mat-icon>
              Profile
            </button>
            <button mat-menu-item (click)="logout()">
              Logout
              <mat-icon>logout</mat-icon>
            </button>
          </mat-menu>
        </div>
        <a
          (click)="openDialogSearch()"
          style="cursor: pointer"
          class="search-icon icon"
        >
          <!-- <img src="assets/images/icons/search.png" alt=""> -->
          <i class="ti-search"></i>
        </a>
        <a class="cart-bag icon">
          <!-- <img src="assets/images/icons/bag.png" alt=""> -->
          <i
            *ngIf="ifLogin"
            style="cursor: pointer"
            routerLink="/shop_cart"
            class="ti-shopping-cart"
          ></i>
          <span *ngIf="showCardNotification" class="itemCount">{{
            cardLength
          }}</span>
        </a>

        <!-- <a href="#" class="wishlist icon">
                    <i class="ti-heart"></i>
                    <span class="itemCount">09</span>
                </a> -->
      </div>
    </nav>
  </div>
</header>
