<section>
  <strong>Edit order</strong>
  <hr />
  <div>
    <div class="d-flex justify-content-between">
      <p>
        {{
          orderProductsForEditing?.length > 1
            ? "Current Products:"
            : "Current Product:"
        }}
      </p>
      <small class="updateTips"
        >If you make the amount <strong>ZERO</strong> the product will be
        removed</small
      >
    </div>

    <div>
      <table class="table-style">
        <thead *ngIf="product_arr?.length">
          <tr>
            <th>Product name</th>
            <th>Amount</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of orderProductsForEditing; let i = index">
            <td>
              {{ item?.ordersproducts?.name }}
            </td>
            <td
              class="d-flex justify-content-between align-items-center"
              [formGroup]="currentProductForm"
            >
              <input
                [formControl]="currentProductAmountControls[i]"
                type="number"
                value="{{ item?.ammount }}"
                class="form-control"
                (change)="
                  changeProductAmount(
                    item?.ordersproducts?.id,
                    currentProductAmountControls[i].value
                  )
                "
              />
            </td>
          </tr>
        </tbody>

        <thead *ngIf="packagesArr.length">
          <tr>
            <th>Package name</th>
            <th>Amount</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of orderPackagesForEditing; let i = index">
            <td>
              {{ item?.orderspackages?.name }}
            </td>
            <td
              class="d-flex justify-content-between align-items-center"
              [formGroup]="currentProductForm"
            >
              <input
                [formControl]="currentPackageAmountControls[i]"
                type="number"
                value="{{ item?.ammount }}"
                class="form-control"
                (change)="
                  changePackageAmount(
                    item?.orderspackages?.id,
                    currentPackageAmountControls[i].value
                  )
                "
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <hr />

  <div *ngIf="packImageStatus">
    <div>
      <p>Updated products:</p>
    </div>

    <div class="mb-2">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>Choose category</mat-label>
        <mat-select
          fullWidth
          placeholder="Select category"
          class="d-felx justify-content-center align-items-center"
        >
          <mat-option
            *ngFor="let item of cat_arr"
            [value]="item?.id"
            (click)="getCatId(item)"
          >
            <strong>
              {{ item?.name | titlecase }}
            </strong>
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <!-- <div class="form-group">
      <div class="dropdown">
        <button
          class="btn btn-secondary dropdown-toggle"
          type="button"
          id="dropdownMenuButton1"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          Choose Category
        </button>
        <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
          <li>
            <a
              class="dropdown-item"
              (click)="getCatId(item)"
              *ngFor="let item of cat_arr"
              >{{ item?.name }}</a
            >
          </li>
        </ul>
      </div>
      <p style="margin-top: 5px; margin-left: 5px" *ngIf="catName">
        {{ catName }}
      </p>
    </div> -->

    <div class="mb-2" *ngIf="status_prod">
      <mat-form-field appearance="outline" class="w-100" *ngIf="ifProd">
        <mat-label>Choose Product</mat-label>
        <mat-select
          fullWidth
          placeholder="Select Product"
          class="d-felx justify-content-center align-items-center"
        >
          <mat-option
            class="productsName"
            *ngFor="let item of prod_arr"
            [value]="item?.id"
            (click)="choseProd(item)"
          >
            <strong>
              {{ item?.name | titlecase }}
            </strong>
          </mat-option>
        </mat-select>
      </mat-form-field>

      <p *ngIf="!ifProd">No Product Found in this Category</p>
    </div>

    <!-- <div class="form-group" *ngIf="status_prod">
      <div class="dropdown" *ngIf="ifProd">
        <button
          class="btn btn-secondary dropdown-toggle"
          type="button"
          id="dropdownMenuButton1"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          Choose Product
        </button>
        <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
          <li *ngFor="let item of prod_arr">
            <a
              class="dropdown-item"
              *ngIf="item.quantity > 0"
              (click)="choseProd(item)"
            >
              {{ item.name }}</a
            >
          </li>
        </ul>
      </div>
      <p *ngIf="ifProd" style="margin-top: 5px; margin-left: 5px">
        {{ prodName }}
      </p>
      <p *ngIf="!ifProd">No Product Found in this Category</p>
    </div> -->

    <div class="form-group" *ngIf="ifProd">
      <label>Product Quantity</label>
      <input type="number" [(ngModel)]="Quantity" class="form-control" />
    </div>
    <button *ngIf="ifProd" class="btn btn-success" (click)="add()">
      Add product
    </button>

    <hr *ngIf="ifProd" />
    <div>
      <!-- <ul>
        <li *ngFor="let item of arrayChoiceProduct">
          {{ item }}
        </li>
      </ul> -->

      <table class="table-style" *ngIf="arrayChoiceProduct?.length">
        <thead>
          <tr>
            <th>Product name</th>
            <th>Amount</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of arrayChoiceProduct">
            <td>
              {{ item?.prodName }}
            </td>
            <td>
              {{ item?.amount }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <div *ngIf="packImageStatus">
    <div>
      <p>Updated packages:</p>
    </div>

    <div class="mb-2">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>Choose package</mat-label>
        <mat-select
          fullWidth
          placeholder="Select package"
          class="d-felx justify-content-center align-items-center"
        >
          <mat-option
            *ngFor="let item of package_arr"
            [value]="item?.id"
            (click)="selectPackageFromList(item)"
          >
            <strong>
              {{ item?.name | titlecase }}
            </strong>
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <!-- <div class="form-group">
      <div class="dropdown">
        <button
          class="btn btn-secondary dropdown-toggle"
          type="button"
          id="dropdownMenuButton1"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          Choose package
        </button>
        <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
          <li>
            <a
              class="dropdown-item"
              (click)="selectPackageFromList(item)"
              *ngFor="let item of package_arr"
              >{{ item.name }}</a
            >
          </li>
        </ul>
      </div>
      <p style="margin-top: 5px; margin-left: 5px" *ngIf="packageName">
        {{ packageName }}
      </p>
    </div> -->

    <div class="form-group" *ngIf="ifPackage">
      <label>Package Quantity</label>
      <input type="number" [(ngModel)]="packageQuantity" class="form-control" />
    </div>
    <button *ngIf="ifPackage" class="btn btn-success" (click)="addPackage()">
      Add package
    </button>

    <!-- <hr *ngIf="ifPackage" /> -->

    <div>
      <table class="table-style" *ngIf="arrayChoicePackage?.length">
        <thead>
          <tr>
            <th>Package name</th>
            <th>Amount</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of arrayChoicePackage">
            <td>
              {{ item?.packageName }}
            </td>
            <td>
              {{ item?.amount }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <hr />
  <div class="modal-body">
    <div>
      <p>Current Address:</p>
      <div>
        <p class="updateTips" *ngIf="!orderAddressForEditing">
          There is no address for this orders.
        </p>
        <strong *ngIf="orderAddressForEditing">
          {{
            orderAddressForEditing?.address +
              " - " +
              orderAddressForEditing?.city +
              " - " +
              orderAddressForEditing?.governorate
          }}</strong
        >
      </div>
    </div>
    <hr />

    <div *ngIf="packImageStatus">
      <div class="d-flex justify-content-between">
        <p>Updated Address:</p>
        <small class="updateTips"
          >New <strong>address</strong> will replace the current address</small
        >
      </div>
      <button
        *ngIf="!showChangingAddress && !showAddingNewAddressForm"
        (click)="changeAddress()"
        type="button"
        class="btn mb-2 small-button"
      >
        Change address
      </button>
    </div>
  </div>

  <div
    *ngIf="showChangingAddress && clientAddressForUpdatingAddress"
    class="addressCompleteDiv"
  >
    <div
      *ngFor="let address of clientAddressForUpdatingAddress"
      class="addressesDiv"
    >
      <input
        class="me-2"
        type="radio"
        id="radio_{{ address.id }}"
        [value]="address?.id"
        name="address"
        (change)="selectAddress(address?.id)"
      />
      <label for="radio_{{ address.id }}">
        <div class="d-flex align-items-center">
          <strong
            >{{ address?.city | titlecase
            }}<small class="ms-2">{{ address?.governorate }}</small></strong
          >
        </div>
        <p>{{ address?.addresss }}</p></label
      ><br />
    </div>

    <button
      *ngIf="!showAddingNewAddressForm"
      (click)="addNewAddress()"
      type="button"
      class="btn btn-success mb-2 small-button"
    >
      Add new address
    </button>
  </div>

  <div *ngIf="showAddingNewAddressForm" class="modal-body">
    <form [formGroup]="clientAddress" *ngIf="showAddingNewAddressForm">
      <div class="form-group">
        <label>City</label>
        <input formControlName="city" type="text" class="form-control" />
      </div>
      <div class="form-group">
        <label>Governorate</label>
        <input formControlName="governorate" type="text" class="form-control" />
      </div>
      <div class="form-group">
        <label>Address</label>
        <input formControlName="address" type="text" class="form-control" />
      </div>
    </form>
    <div class="d-flex justify-content-between align-items-center">
      <button
        title="Save the new address"
        class="btn small-button"
        [disabled]="!clientAddress.valid"
        *ngIf="showAddingNewAddressForm"
        (click)="saveAddress()"
      >
        Save address
      </button>

      <button
        title="Back to client addresses"
        class="btn small-button"
        *ngIf="showAddingNewAddressForm"
        (click)="backToClientAddressList()"
      >
        Back
      </button>
    </div>
  </div>

  <hr />

  <div class="mb-3" [formGroup]="phoneNoForm">
    <div>
      <div>
        <label>Phone number</label>
        <input formControlName="phoneNumber" type="text" class="form-control" />

        <span
          class="text-danger"
          *ngIf="phoneNoForm.controls.phoneNumber.errors?.invalidEmailOrPhone"
        >
          Please enter a valid Phone number
        </span>
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <button class="btn editOrder" (click)="save()">Save Order</button>
  </div>
</section>
