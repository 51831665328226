import { Component, OnInit } from '@angular/core';
import { RestService } from 'src/app/services/rest.service';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-packges',
  templateUrl: './packges.component.html',
  styleUrls: ['./packges.component.scss'],
})
export class PackgesComponent implements OnInit {
  allPackages: any = [];
  showMessage = true;
  constructor(private rest: RestService, private route: Router) {}

  ngOnInit() {
    this.getData();
  }

  getData() {
    this.rest.allPackage().subscribe((res: any) => {
      this.allPackages = res;
      if (this.allPackages.length == 0) {
        this.showMessage = true;
      } else {
        this.showMessage = false;
      }
      this.allPackages.forEach((element: any) => {
        if (element.image != null) {
          element.image = `${environment.apiUrl}/images/?id=${element.image}`;
          // this._sanitizer.bypassSecurityTrustUrl(`data:image/png;base64,`+ element.image);
        } else {
          element.image = 'assets/images/rk.jpeg';
        }
      });
    });
  }

  pack_details(id: any) {
    this.route.navigate(['/packges_details', id]);
  }
}
