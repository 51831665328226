<section>
  <h3>Request Product</h3>
  <hr />
  <div>
    <form [formGroup]="clientAddress">
      <div class="form-group">
        <label>Prdouct Name</label>
        <input formControlName="name" type="text" class="form-control" />
        <span
          class="text-danger"
          *ngIf="
            clientAddress.controls.name.touched &&
            clientAddress.controls.name.errors?.required
          "
        >
          Name is Required
        </span>
      </div>

      <div class="form-group">
        <label>Product Url</label>
        <input formControlName="url" type="text" class="form-control" />
      </div>
      <div class="form-group">
        <label>Product Image</label>
        <br />
        <input
          formControlName="image"
          (change)="onFileUpload($event)"
          type="file"
        />
      </div>
      <button (click)="onNoClick()" class="btn">Save</button>
    </form>
  </div>
</section>
