import { Component, OnInit } from '@angular/core';
import { RestService } from 'src/app/services/rest.service';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-shop',
  templateUrl: './shop.component.html',
  styleUrls: ['./shop.component.scss'],
})
export class ShopComponent implements OnInit {
  products: any;
  rating: any = [];
  isLoading: boolean = true;

  page: number = 1;
  limit: number = 20;
  totalCount: number = 10;

  constructor(private rest: RestService, private routing: Router) {}
  ngOnInit() {
    this.isLoading = true;
    this.getData();
  }

  getData() {
    this.rest.allProduct(this.page, this.limit).subscribe((res: any) => {
      this.isLoading = false;
      res?.results.forEach((element: any) => {
        this.rating = [];
        for (let i = 0; i < element.rating; i++) {
          this.rating.push('1');
        }
        element.rating_arr = this.rating;
        // if(element.images.length != 0){
        //   element.images[0].image = this._sanitizer.bypassSecurityTrustUrl(`data:image/png;base64,`+ element.images[0].image);
        // }else {
        //   element.images.push({
        //     image:"assets/images/rk.jpeg"
        //   })
        // }
        if (element.images.length != 0) {
          element.images[0].image_url = `${environment.apiUrl}/images/?id=${element.images[0].image_url}`;
        } else {
          element.images[0] = {
            image_url: 'assets/images/rk.jpeg',
          };
        }
      });
      this.products = res?.results;
      this.totalCount = res?.total_count;
    });
  }

  pageChanged(event: any) {
    this.isLoading = true;
    this.page = event;
    this.getData();
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }

  productDetails(value: any) {
    this.routing.navigate(['/products_details', value.id]);
  }
}
