<section class="container">
  <div class="row">
    <div class="col-12 col-md-6">
      <swiper [config]="config">
        <ng-template swiperSlide *ngFor="let item of mainImages_arr">
          <div class="product-item">
            <img [src]="item.image_url" alt="" />
          </div>
        </ng-template>
      </swiper>
    </div>
    <div class="col-12 col-md-6">
      <section class="main">
        <h2>{{ prodName }}</h2>
        <div class="rate">
          <a href="#" *ngFor="let item of rateArr"><i class="ti-star"></i></a>
        </div>
        <h2>{{ prodPrice }} LE</h2>
        <!-- <p>
                    Your ultimate favorite Ombre is back in a gorgeous rose hue! Super soft, light and breathable Modal,
                    a
                    gorgeous material for scarves. Featured here in an Ombré of a feminine gradient hues of rose &
                    white.
                    Everyday simplicity.
                </p> -->

        <!-- <div class="size">
                    <h4>Size</h4>
                    <span>S</span>
                    <span>M</span>
                    <span>L</span>
                    <span>XL</span>
                </div> -->
        <div class="addToCart mb-4">
          <div class="plus">
            <button class="btn" (click)="plus()">+</button>
            <span>{{ count }}</span>
            <button class="btn" (click)="minus()">-</button>
          </div>
          <button class="btn btn_addCart" (click)="addToCart()">
            Add to Cart
          </button>
        </div>

        <strong *ngIf="stock == 0" class="zeroStock"
          >Order will arrive in 14 business days.</strong
        >
      </section>
      <hr />
      <h3 class="desc">Product Description</h3>
      <ul>
        <li *ngFor="let item of description">
          {{ item }}
        </li>
      </ul>
      <!-- <p >

            </p> -->

      <!-- <button class="btn stock" style="margin-top: 5px">In stock : {{stock}}</button> -->
    </div>
  </div>

  <br />
  <br />
  <hr *ngIf="screenShootImage.length != 0" />
  <div *ngIf="screenShootImage.length != 0">
    <h2>Screenshots</h2>
    <div class="row">
      <div class="col-12 col-md-4 mb-5" *ngFor="let item of screenShootImage">
        <!-- <swiper [config]="config">
                    <ng-template swiperSlide >
                        <div class="product-item">
                            <img [src]="item.image_url" alt="">
                        </div>
                    </ng-template>

                </swiper> -->
        <div class="screenDesgin">
          <img class="img-fulid" [src]="item.image_url" />
        </div>
      </div>
    </div>
  </div>

  <br />
  <br />
  <hr *ngIf="videos_arr.length != 0" />

  <div *ngIf="videos_arr.length != 0">
    <h2>Videos</h2>
    <div class="row">
      <div class="col-12 col-md-6" *ngFor="let item of videos_arr">
        <video controls>
          <source src="{{ item.image_url }}" type="video/mp4" />
          <source src="{{ item.image_url }}" type="video/ogg" />
        </video>
      </div>
    </div>
  </div>

  <hr />
  <div class="row">
    <div class="col-12 col-md-3">
      <h4>REVIEWS ({{ reviews.length }})</h4>
    </div>
    <div class="col-12 col-md-9">
      <h4>{{ reviews.length }} Reviews For {{ prodName }}</h4>
      <div class="review">
        <div class="row" *ngFor="let item of reviews">
          <div class="col-2">
            <img class="img-fluid" src="assets/images/user.png" />
          </div>
          <div class="col-10">
            <div>
              <div class="rate">
                <a href="#" *ngFor="let rate of item.arr"
                  ><i class="ti-star"></i
                ></a>
              </div>
              <p>
                {{ item.client }} – {{ item.joined_date | date : "yyyy/MM/dd" }}
              </p>
              <p>{{ item.review }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="addReview" *ngIf="clientID">
        <h4>Add a review</h4>
        <p>Your rating *</p>
        <div class="addRate">
          <div class="rate" (click)="goActiveRate('1')">
            <a
              [ngClass]="[
                activeRate === true && rateId == '1' ? 'activeRate' : 'dot'
              ]"
              ><i class="ti-star"></i
            ></a>
          </div>
          <div class="rate" (click)="goActiveRate('2')">
            <a
              [ngClass]="[
                activeRate === true && rateId == '2' ? 'activeRate' : 'dot'
              ]"
              ><i class="ti-star"></i
            ></a>
            <a
              [ngClass]="[
                activeRate === true && rateId == '2' ? 'activeRate' : 'dot'
              ]"
              ><i class="ti-star"></i
            ></a>
          </div>
          <div class="rate" (click)="goActiveRate('3')">
            <a
              [ngClass]="[
                activeRate === true && rateId == '3' ? 'activeRate' : 'dot'
              ]"
              ><i class="ti-star"></i
            ></a>
            <a
              [ngClass]="[
                activeRate === true && rateId == '3' ? 'activeRate' : 'dot'
              ]"
              ><i class="ti-star"></i
            ></a>
            <a
              [ngClass]="[
                activeRate === true && rateId == '3' ? 'activeRate' : 'dot'
              ]"
              ><i class="ti-star"></i
            ></a>
          </div>
          <div class="rate" (click)="goActiveRate('4')">
            <a
              [ngClass]="[
                activeRate === true && rateId == '4' ? 'activeRate' : 'dot'
              ]"
              ><i class="ti-star"></i
            ></a>
            <a
              [ngClass]="[
                activeRate === true && rateId == '4' ? 'activeRate' : 'dot'
              ]"
            >
              <i class="ti-star"></i
            ></a>
            <a
              [ngClass]="[
                activeRate === true && rateId == '4' ? 'activeRate' : 'dot'
              ]"
              ><i class="ti-star"></i
            ></a>
            <a
              [ngClass]="[
                activeRate === true && rateId == '4' ? 'activeRate' : 'dot'
              ]"
              ><i class="ti-star"></i
            ></a>
          </div>
          <div class="rate" (click)="goActiveRate('5')">
            <a
              [ngClass]="[
                activeRate === true && rateId == '5' ? 'activeRate' : 'dot'
              ]"
              ><i class="ti-star"></i
            ></a>
            <a
              [ngClass]="[
                activeRate === true && rateId == '5' ? 'activeRate' : 'dot'
              ]"
              ><i class="ti-star"></i
            ></a>
            <a
              [ngClass]="[
                activeRate === true && rateId == '5' ? 'activeRate' : 'dot'
              ]"
              ><i class="ti-star"></i
            ></a>
            <a
              [ngClass]="[
                activeRate === true && rateId == '5' ? 'activeRate' : 'dot'
              ]"
              ><i class="ti-star"></i
            ></a>
            <a
              [ngClass]="[
                activeRate === true && rateId == '5' ? 'activeRate' : 'dot'
              ]"
              ><i class="ti-star"></i
            ></a>
          </div>
        </div>
        <div class="form-group">
          <label>Your review *</label>
          <textarea class="form-control" [(ngModel)]="textArea"></textarea>
        </div>
        <button class="btn submit" (click)="addReview()">Submit</button>
      </div>
    </div>
  </div>
</section>
