import { Component } from '@angular/core';
import { UserProfileComponent } from '../user-profile/user-profile.component';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-cancel-confirm',
  templateUrl: './cancel-confirm.component.html',
  styleUrls: ['./cancel-confirm.component.scss'],
})
export class CancelConfirmComponent {
  constructor(public dialogRef: MatDialogRef<UserProfileComponent>) {}

  sendData(value: any) {
    this.dialogRef.close(value);
  }
}
