import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { HeaderComponent } from '../header/header.component';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
})
export class SearchComponent {
  searchName = '';
  constructor(public dialogRef: MatDialogRef<HeaderComponent>) {}

  onNoClick(): void {
    this.dialogRef.close(this.searchName);
  }
  change(text: any) {
    localStorage.setItem('searchText', text.target.value);
  }
}
