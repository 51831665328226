import { Component, OnInit } from '@angular/core';
import { RestService } from 'src/app/services/rest.service';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { RequestProductComponent } from '../request-product/request-product.component';
import { SearchComponent } from '../search/search.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  ifLogin = false;
  userEmail: any = '';
  showCardNotification = false;
  cardLength = 0;
  access_token: any;
  showRequestProduct = false;
  constructor(
    private rest: RestService,
    public dialog: MatDialog,
    private route: Router
  ) {}

  ngOnInit() {
    let cart: any = localStorage.getItem('cart');
    cart = JSON.parse(cart);
    if (localStorage.getItem('user')) {
      this.ifLogin = true;
      this.showRequestProduct = true;
      this.userEmail = localStorage.getItem('user');
      this.access_token = localStorage.getItem('token');
    } else {
      this.ifLogin = false;
      this.showRequestProduct = false;
    }
    if (cart) {
      if (cart.length != 0) {
        this.cardLength = cart.length;
        this.showCardNotification = true;
      } else {
        this.showCardNotification = false;
      }
    }

    this.rest.getDataIfLogin().subscribe((res: any) => {
      this.access_token = localStorage.getItem('token');
      this.userEmail = localStorage.getItem('user');
      this.ifLogin = true;
      this.showRequestProduct = true;
      this.rest.getCart(this.access_token).subscribe((res: any) => {
        if (res) {
          this.cardLength = res.length;
          this.showCardNotification = true;
        } else {
          this.cardLength = 0;
          this.showCardNotification = false;
        }
      });
    });

    this.rest.getDataCard().subscribe((res: any) => {
      var cart: any = localStorage.getItem('cart');
      cart = JSON.parse(cart);
      if (cart) {
        if (cart.length != 0) {
          this.cardLength = cart.length;
          this.showCardNotification = true;
        } else {
          this.showCardNotification = false;
        }
      } else {
        this.cardLength = 1;
      }
      if (res == true) {
        this.showCardNotification = true;
      } else {
        this.showCardNotification = false;
      }
    });
  }

  logout() {
    let token = localStorage.getItem('token');
    let cart: any = localStorage.getItem('cart');
    cart = JSON.parse(cart);
    this.rest.postCART(cart, token).subscribe((res: any) => {});
    localStorage.clear();
    this.route.navigateByUrl('/');
    this.rest.SendSignOut(true);
    this.ifLogin = false;
    this.showRequestProduct = false;
    this.showCardNotification = false;
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(RequestProductComponent, {
      width: '500px',
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        // this.rest.requestProduct(result,this.access_token).subscribe((res :any) => {
        //   this.rest.successToast('Your request is being processed and you will receive an email for confirmation')
        // })

        this.rest
          .requestProduct(result, this.access_token)
          .then((response) => response.text())
          .then((result) => {
            this.rest.successToast(
              'Your request is being processed and you will receive an email for confirmation'
            );
          })
          .catch((error) => console.log('error', error));
      }
    });
  }

  openDialogSearch(): void {
    const dialogRef = this.dialog.open(SearchComponent, {
      width: '600px',
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        if (this.route.url == '/search_result') {
          location.reload();
        } else {
          this.route.navigateByUrl('/search_result');
        }
      }
    });
  }
}
