<section>
  <div class="container">
    <div *ngIf="showMessage" class="text-center">
      <h1>Stay tuned for our coming packages</h1>
    </div>
    <div class="row" *ngIf="!showMessage">
      <div
        class="col-12 col-md-4 pack"
        *ngFor="let item of allPackages"
        (click)="pack_details(item?.id)"
      >
        <div class="cardStyle">
          <img [src]="item?.image" />
          <h2>{{ item?.name }}</h2>
        </div>
      </div>
    </div>
  </div>
</section>
