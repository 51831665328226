import { Component, OnInit } from '@angular/core';
import SwiperCore, {
  Autoplay,
  Keyboard,
  Pagination,
  Scrollbar,
  Zoom,
  SwiperOptions,
} from 'swiper';
import { ActivatedRoute, Router } from '@angular/router';
import { RestService } from 'src/app/services/rest.service';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { MetaService } from 'src/app/services/meta.service';
SwiperCore.use([Autoplay, Keyboard, Pagination, Scrollbar, Zoom]);
@Component({
  selector: 'app-products-details',
  templateUrl: './products-details.component.html',
  styleUrls: ['./products-details.component.scss'],
})
export class ProductsDetailsComponent implements OnInit {
  config: SwiperOptions = {
    slidesPerView: 1,
    spaceBetween: 20,
    pagination: { clickable: true },
    //  autoplay:true
  };
  prodId: any;
  rateArr: any = [];
  prodName: any = '';
  images: any = [];
  description: any = '';
  stock: any = '';
  prodPrice: any;
  reviews: any = [];
  prodObj: any;
  count = 1;
  activeRate = false;
  textArea: any;
  token: any;
  clientID: any;
  mainImages_arr: any = [];
  screenShootImage: any = [];
  videos_arr: any = [];
  constructor(
    private route: ActivatedRoute,
    private routing: Router,
    private _sanitizer: DomSanitizer,
    private rest: RestService,
    private metaService: MetaService
  ) {}

  ngOnInit() {
    this.token = localStorage.getItem('token');
    this.clientID = localStorage.getItem('clientID');
    this.prodId = this.route.snapshot.paramMap.get('id');
    this.getData();

    // Update meta tags dynamically
    this.metaService.updateMetaTags(
      this.prodName,
      this.description,
      this.mainImages_arr[0],
      window.location.href
    );
  }

  getData() {
    this.rest.prodDetails(this.prodId).subscribe((res: any) => {
      this.prodName = res.name;
      this.description = res.description;
      if (res.description.includes('\u2022')) {
        this.description = this.description.split('\u2022');
      } else {
        this.description = this.description.split('.');
      }
      this.stock = res.quantity;
      this.prodPrice = res.price;
      this.prodObj = res;

      if (res.images.length == 0) {
        res.images.push({
          image: 'assets/images/rk.jpeg',
        });
      } else {
        res.images.forEach((element: any) => {
          if (element.image_type == null) {
            element.image_url = `${environment.apiUrl}/images/?id=${element.image_url}`;
            this.mainImages_arr.push(element);
          } else {
            if (element.image_type.includes('main')) {
              element.image_url = `${environment.apiUrl}/images/?id=${element.image_url}`;
              this.mainImages_arr.push(element);
            }
            if (element.image_type.includes('screenshot')) {
              element.image_url = `${environment.apiUrl}/images/?id=${element.image_url}`;
              this.screenShootImage.push(element);
            }
            if (element.image_type.includes('video')) {
              element.image_url = `${environment.apiUrl}/images/?id=${element.image_url}`;
              this.videos_arr.push(element);
            }
          }
        });
      }
      this.images = res.images;
      this.rateArr = new Array(res.rating);
      res.reviews.forEach((element: any) => {
        element.arr = new Array(element.rating);
      });
      this.reviews = res.reviews;
    });
  }
  addToCart() {
    localStorage.getItem('token');
    if (localStorage.getItem('token')) {
      let carts: any = localStorage.getItem('cart');
      carts = JSON.parse(carts);
      this.prodObj.count = this.count;

      if (carts) {
        const existingItem = carts.find(
          (cart: any) => cart?.id === this.prodObj.id
        );
        if (existingItem) {
          existingItem.count += 1;
        } else {
          carts.push(this.prodObj);
        }
        localStorage.setItem('cart', JSON.stringify(carts));
        this.rest.successToast('Product Added in Your Cart');
        this.rest.postCART(carts, this.token).subscribe((res: any) => {});
      } else {
        let cart = [];
        cart.push(this.prodObj);
        localStorage.setItem('cart', JSON.stringify(cart));
        this.rest.successToast('Product Added in Your Cart');
        this.rest.postCART(cart, this.token).subscribe((res: any) => {});
      }

      this.rest.SendDataCard(true);
    } else {
      this.routing.navigateByUrl('/login');
    }
  }

  plus() {
    this.count = this.count + 1;
  }
  minus() {
    if (this.count > 1) {
      this.count = this.count - 1;
    }
  }

  rateId: string | any;
  goActiveRate(id: any) {
    this.activeRate = !this.activeRate;
    if (id != this.rateId) {
      this.activeRate = true;
    }
    this.rateId = id;
  }

  addReview() {
    let obj = {
      client_id: this.clientID,
      product_id: this.prodId,
      rating: this.rateId,
      review: this.textArea,
    };
    this.rest.addRate(obj, this.token).subscribe((res: any) => {
      this.textArea = '';
      this.rateId = 0;
      this.getData();
    });
  }
}
