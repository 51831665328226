import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RestService } from 'src/app/services/rest.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss'],
})
export class ProductsComponent implements OnInit {
  cat_id: any;
  cat_name: any = '';
  products: any = [];
  rating: any = [];

  isLoading: boolean = true;
  page: number = 1;
  limit: number = 20;
  totalCount: number = 10;

  constructor(
    private route: ActivatedRoute,
    private routing: Router,
    private rest: RestService
  ) {}

  ngOnInit() {
    this.cat_id = this.route.snapshot.paramMap.get('id');
    this.isLoading = true;
    this.getData();
  }

  getData() {
    this.rest
      .categoryDetails(this.page, this.limit, this.cat_id)
      .subscribe((res: any) => {
        this.isLoading = false;

        this.cat_name = res.name;
        res.results.forEach((element: any) => {
          for (let i = 0; i < element.rating; i++) {
            this.rating.push('1');
          }
          element.rating_arr = this.rating;

          if (element.images.length != 0) {
            element.images[0].image_url = `${environment.apiUrl}/images/?id=${element.images[0].image_url}`;
          } else {
            element.images[0] = {
              image_url: 'assets/images/rk.jpeg',
            };
          }
        });
        this.products = res.results;
        this.totalCount = res?.total_count;
      });
  }

  pageChanged(event: any) {
    this.isLoading = true;
    this.page = event;
    this.getData();
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }

  productDetails(value: any) {
    this.routing.navigate(['/products_details', value.id]);
  }
}
