<section style="background-color: #f9f9f9">
  <div class="container py-5 h-100">
    <div class="row d-flex justify-content-center align-items-center h-100">
      <div class="col col-xl-10">
        <div class="card" style="border-radius: 1rem">
          <div class="row g-0">
            <div
              class="col-md-6 offset-md-3 col-lg-7 d-flex align-items-center"
            >
              <div class="card-body p-4 p-lg-5 text-black">
                <form [formGroup]="loginForm">
                  <div class="d-flex align-items-center mb-3 pb-1">
                    <img
                      style="width: 100px; margin: 0 auto"
                      src="assets/images/Logo ver 1.png"
                    />
                  </div>

                  <h5 class="fw-normal mb-3 pb-3" style="letter-spacing: 1px">
                    Sign into your account
                  </h5>

                  <div class="form-outline mb-4">
                    <label
                      class="form-label d-flex justify-content-between"
                      for="form2Example17"
                    >
                      Email address or Phone number
                    </label>
                    <input
                      type="email"
                      formControlName="emailOrPhone"
                      id="form2Example17"
                      class="form-control form-control-lg"
                    />
                    <span
                      class="text-danger"
                      *ngIf="
                        loginForm.controls.emailOrPhone.touched &&
                        loginForm.controls.emailOrPhone.errors?.required
                      "
                    >
                      Email or Phone number is required
                    </span>
                    <span
                      class="text-danger"
                      *ngIf="
                        loginForm.controls.emailOrPhone.errors
                          ?.invalidEmailOrPhone
                      "
                    >
                      Please enter a valid Email or Phone number
                    </span>
                  </div>

                  <div class="form-outline mb-4">
                    <label class="form-label" for="form2Example27"
                      >Password</label
                    >
                    <input
                      type="password"
                      id="form2Example27"
                      formControlName="password"
                      class="form-control form-control-lg"
                    />
                  </div>

                  <div class="pt-1 mb-4">
                    <button
                      (click)="login()"
                      class="btn btn-dark btn-lg btn-block"
                      type="button"
                      [disabled]="loginForm?.invalid"
                    >
                      Login
                    </button>
                  </div>
                  <!-- <div style="text-align: center">
                    <p>OR</p>
                  </div>
                  <div class="logInFb" (click)="loginWithFacebook()">
                    <img src="assets/images/facebook.png" />
                  </div> -->

                  <a class="small text-muted" routerLink="/forget_pw"
                    >Forgot password?</a
                  >
                  <p class="mb-5 pb-lg-2" style="color: #393f81">
                    Don't have an account?
                    <a routerLink="/sign_up" href="#!" style="color: #393f81"
                      >Register here</a
                    >
                  </p>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
