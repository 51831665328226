import { Component, OnInit } from '@angular/core';
import { RestService } from 'src/app/services/rest.service';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-blogs',
  templateUrl: './blogs.component.html',
  styleUrls: ['./blogs.component.scss']
})
export class BlogsComponent implements OnInit {

  blogs_arr: any
  constructor(private rest: RestService, private route: Router) { }


  ngOnInit() {
    this.getData()
  }

  getData() {
    this.rest.getBlogs().subscribe((res: any) => {
      this.blogs_arr = res
      this.blogs_arr.forEach((element: any) => {
        if(element.media.length != 0 ){
          element.media[0].image_url = `${environment.apiUrl}/images/?id=${element.media[0].image_url}`
        }else {
          element.media.push({
            image_url : 'assets/images/rk.jpeg'
          })
        }
      });
    })
  }

  blogDetails(id: any) {
    this.route.navigate(['blog_details', id])
  }

}
