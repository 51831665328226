import { Component, OnInit } from '@angular/core';
import { RestService } from 'src/app/services/rest.service';
import { MatDialog } from '@angular/material/dialog';
import { AddresComponent } from '../addres/addres.component';
import { Router } from '@angular/router';
import { AddresInfoComponent } from '../addres-info/addres-info.component';

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss'],
})
export class CheckoutComponent implements OnInit {
  cart: any;
  clientID: any;
  addressId: any;
  token: any;
  arrOfProd: any = [];
  arrOfPackages: any = [];
  cashed = true;
  clientObj: any;
  promoCode: any;
  fees: any;
  constructor(
    private rest: RestService,
    private route: Router,
    public dialog: MatDialog
  ) {}

  ngOnInit() {
    this.token = localStorage.getItem('token');
    this.clientID = localStorage.getItem('clientID');
    this.getClientData();
    this.cart = localStorage.getItem('cart');
    this.cart = JSON.parse(this.cart);
    this.fees = this.cart[0].fees;
    if (this.cart[0].promocode) {
      this.promoCode = this.cart[0].promocode;
    } else {
      this.promoCode = '';
    }
    this.cart.forEach((element: any) => {
      if (element.count) {
        this.arrOfProd.push({
          id: element.id,
          quantity: element.count,
        });
      }
      if (!element.count) {
        this.arrOfPackages.push({
          id: element.id,
          amount: 1,
        });
      }
    });
  }

  getClientData() {
    this.rest.userData(this.clientID, this.token).subscribe((res: any) => {
      this.clientObj = res;
    });
  }

  checkout() {
    let obj = {
      client_id: this.clientID,
      products: this.arrOfProd,
      cashed: this.cashed,
      packages: this.arrOfPackages,
      promocode: this.promoCode,
      fees: this.fees,
      address_id: this.addressId,
    };
    this.rest.addOrder(obj, this.token).subscribe(
      (res: any) => {
        this.rest.successToast('Order received');
        localStorage.removeItem('cart');
        this.rest.deleteCart(this.token).subscribe((res: any) => {});
        this.rest.SendDataCard('true');
        this.route.navigateByUrl('/home');
      },
      (err: any) => {
        this.rest.errorToaster('Something Wrong Pleas Try Again');
      }
    );
  }

  radioValue(e: any) {
    this.cashed = e;
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(AddresComponent, {
      width: '500px',
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.addressId = result;
        this.checkout();

        // let results = result;
        // results.id = this.clientID;
        // this.rest
        //   .updateClientData(results, this.token)
        //   .subscribe((res: any) => {
        //     this.checkout();
        //   });
      }
    });
  }

  openAddressInfo(): void {
    if (
      this.clientObj?.address == null ||
      this.clientObj?.city == null ||
      this.clientObj?.governorate == null
    ) {
      this.openDialog();
    } else {
      const dialogRef = this.dialog.open(AddresInfoComponent, {
        width: '500px',
        data: this.clientObj,
      });
      dialogRef.afterClosed().subscribe((result: any) => {
        if (result) {
          if (result == 'true') {
            this.checkout();
          } else {
            this.openDialog();
          }
        }
      });
    }
  }
}
