<section>
  <div class="container">
    <div class="row">
      <div class="col-12 col-md-5 offset-md-1 imageAbout">
        <img src="assets/images/rkk.jpeg" />
      </div>
      <div class="col-12 col-md-5 about">
        <h1>About Us</h1>
        <p>
          Hello, I'm Radwa Khallaf and this is my new home, it all started a few
          years ago with some reviews we gave each other about some simple
          products. we all shared our opinions for the sole purpose of giving
          advice just for the simple reason of feeling good. our family grew
          till we became 85k+ members on our Facebook group and that's when we
          decided to take the next step
          <br />
          <br />
          Take your tour and join us, it would be my honor, my pleasure.
        </p>

        <p class="radwa">Radwa Khallaf</p>
      </div>
    </div>
  </div>
</section>
