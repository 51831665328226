<section>
  <div class="container h-100">
    <div class="row d-flex justify-content-center align-items-center h-100">
      <div class="col-lg-12 col-xl-11">
        <div class="card text-black" style="border-radius: 25px">
          <div class="card-body p-md-5">
            <div class="row justify-content-center">
              <div class="col-md-10 col-lg-6 col-xl-5 order-2 order-lg-1">
                <p class="text-center h1 fw-bold mb-5 mx-1 mx-md-4 mt-4">
                  Sign up
                </p>

                <form class="mx-1 mx-md-4" [formGroup]="formRegister">
                  <div class="d-flex flex-row align-items-center mb-4">
                    <i class="fas fa-user fa-lg me-3 fa-fw"></i>
                    <div class="form-outline flex-fill mb-0">
                      <label class="form-label" for="form3Example1c"
                        >User Name</label
                      >
                      <input
                        type="text"
                        formControlName="username"
                        id="form3Example1c"
                        class="form-control"
                      />
                      <span
                        class="text-danger"
                        *ngIf="
                          (formRegister.controls.username.touched ||
                            formStatus) &&
                          formRegister.controls.username.errors?.required
                        "
                      >
                        User Name is Required
                      </span>
                      <span style="display: block" class="hintText">
                        (Username cannot include spaces)
                      </span>
                    </div>
                  </div>

                  <div class="d-flex flex-row align-items-center mb-4">
                    <i class="fas fa-envelope fa-lg me-3 fa-fw"></i>
                    <div class="form-outline flex-fill mb-0">
                      <label class="form-label" for="form3Example3c"
                        >Your Email</label
                      >
                      <input
                        formControlName="email"
                        type="email"
                        id="form3Example3c"
                        class="form-control"
                      />
                      <span
                        class="text-danger"
                        *ngIf="
                          (formRegister.controls.email.touched || formStatus) &&
                          formRegister.controls.email.errors?.required
                        "
                      >
                        Email is Required
                      </span>
                      <span
                        class="text-danger"
                        *ngIf="formRegister.controls.email.errors?.pattern"
                      >
                        Check Your Email
                      </span>
                    </div>
                  </div>

                  <div class="d-flex flex-row align-items-center mb-4">
                    <i class="fas fa-envelope fa-lg me-3 fa-fw"></i>
                    <div class="form-outline flex-fill mb-0">
                      <label class="form-label" for="form3Example5c"
                        >Phone number</label
                      >
                      <input
                        formControlName="phone_number"
                        type="tel"
                        id="form3Example5c"
                        pattern="^01[0-2|5]\d{1,8}$"
                        class="form-control"
                      />
                      <span
                        class="text-danger"
                        *ngIf="
                          (formRegister.controls.phone_number.touched ||
                            formStatus) &&
                          formRegister.controls.phone_number.errors?.required
                        "
                      >
                        Phone number is Required
                      </span>

                      <span
                        class="text-danger"
                        *ngIf="
                          formRegister.controls.phone_number.errors?.pattern
                        "
                      >
                        Check Your Phone number
                      </span>
                    </div>
                  </div>

                  <div class="d-flex flex-row align-items-center mb-4">
                    <i class="fas fa-lock fa-lg me-3 fa-fw"></i>
                    <div class="form-outline flex-fill mb-0">
                      <label class="form-label" for="form3Example4c"
                        >Password</label
                      >
                      <input
                        formControlName="password"
                        type="password"
                        id="form3Example4c"
                        class="form-control"
                      />
                      <span
                        class="text-danger"
                        *ngIf="
                          (formRegister.controls.password.touched ||
                            formStatus) &&
                          formRegister.controls.password.errors?.required
                        "
                      >
                        Password is Required
                      </span>
                      <span style="display: block" class="hintText">
                        (Password must have 8 char or number)
                      </span>
                    </div>
                  </div>

                  <!-- <div class="d-flex flex-row align-items-center mb-4">
                                        <i class="fas fa-key fa-lg me-3 fa-fw"></i>
                                        <div class="form-outline flex-fill mb-0">
                                            <label class="form-label" for="form3Example4cd">Repeat your password</label>
                                            <input type="password" id="form3Example4cd" class="form-control" />
                                        </div>
                                    </div> -->

                  <div class="d-flex justify-content-center mx-4 mb-3 mb-lg-4">
                    <button
                      type="button"
                      (click)="submit()"
                      class="btn btn-primary btn-lg"
                    >
                      Register
                    </button>
                  </div>
                </form>
              </div>
              <div
                class="col-md-10 col-lg-6 col-xl-7 d-flex align-items-center order-1 order-lg-2"
              >
                <img
                  src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-registration/draw1.webp"
                  class="img-fluid"
                  alt="Sample image"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
