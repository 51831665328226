import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CheckoutComponent } from '../checkout/checkout.component';

@Component({
  selector: 'app-addres-info',
  templateUrl: './addres-info.component.html',
  styleUrls: ['./addres-info.component.scss'],
})
export class AddresInfoComponent implements OnInit {
  city: any;
  goverment: any;
  addres: any;
  constructor(
    public dialogRef: MatDialogRef<CheckoutComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    this.city = this.data.city;
    this.goverment = this.data.governorate;
    this.addres = this.data.address;
  }

  closeDialog(value: any) {
    this.dialogRef.close(value);
  }
}
