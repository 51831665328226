<section>
  <div class="container">
    <div class="title">
      <h1>
        {{ cat_name }}
      </h1>
    </div>

    <div class="main">
      <div class="loader-container" *ngIf="isLoading">
        <div class="loader"></div>
      </div>

      <div class="row">
        <div
          class="col-6 col-md-3"
          *ngFor="
            let item of products
              | paginate
                : {
                    itemsPerPage: 20,
                    currentPage: page,
                    totalItems: totalCount
                  }
          "
        >
          <div class="product-item" (click)="productDetails(item)">
            <figure class="product-thumb">
              <img [src]="item.images[0].image_url" alt="" />
              <div class="action-links">
                <button
                  class="btn btn-cart"
                  [routerLink]="['/products_details', item.id]"
                >
                  Add To Cart
                </button>
              </div>
            </figure>
            <!-- <div class="product-content">
                            <h5 class="product-name"><a>{{item.name}}</a></h5>
                            <div class="ratings" >
                                <a *ngFor="let rate of item.rating_arr" href="#"><i class="ti-star"></i></a>
                            </div>
                            <p class="price">LE.{{item.price}}</p>
                        </div> -->
          </div>
          <div class="product-content">
            <h5 class="product-name text-truncate">
              <a class="">{{ item.name }}</a>
            </h5>
            <div class="ratings">
              <a *ngFor="let rate of item.rating_arr"
                ><i class="ti-star"></i
              ></a>
            </div>
            <div *ngIf="item.rating_arr.length == 0" class="ratings">
              <a style="color: #000"><i class="ti-star"></i></a>
              <a style="color: #000"><i class="ti-star"></i></a>
              <a style="color: #000"><i class="ti-star"></i></a>
              <a style="color: #000"><i class="ti-star"></i></a>
              <a style="color: #000"><i class="ti-star"></i></a>
            </div>
            <p class="price">LE.{{ item.price }}</p>
          </div>
        </div>
      </div>

      <div class="pagination">
        <pagination-controls
          (pageChange)="pageChanged($event)"
          (pageChange)="page = $event"
        ></pagination-controls>
      </div>
    </div>
  </div>
</section>
