import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { RestService } from 'src/app/services/rest.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-blog-details',
  templateUrl: './blog-details.component.html',
  styleUrls: ['./blog-details.component.scss']
})
export class BlogDetailsComponent implements OnInit {

  blogId:any = ""
  title:any = ""
  content:any = ""
  steps:any = ""
  date:any = ""
  products:any = []
  mainImage:any
  videosUrl:any
  videosArr:any = []
  constructor(private route: ActivatedRoute,private rest : RestService){}

  ngOnInit(){
   this.blogId = this.route.snapshot.paramMap.get('id')
   this.getData()
  }

  getData(){
    this.rest.blogDetails(this.blogId).subscribe((res:any) => {
      this.title = res.title
      this.content = res.content
      this.steps = res.steps
      this.date = res.joined_date
      this.products = res.products

      this.products.forEach((element:any) => {
        element.image[0].image_url = `${environment.apiUrl}/images/?id=${element.image[0].image_url}`
      });
      res.media.forEach((element:any) => {
        if(element.image_type.includes('main')){
          element.image_url = `${environment.apiUrl}/images/?id=${element.image_url}`
          this.mainImage = element.image_url
        }
        if(element.image_type.includes('video')){
          element.image_url = `${environment.apiUrl}/images/?id=${element.image_url}`
          this.videosArr.push(element)
          // this.videosUrl = element.image_url
        }
      });
    })
  }

}
