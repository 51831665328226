<section>
    <div class="container">
        <h2 class="text-center">Our Blogs</h2>
        <div class="mx-5">
            <div class="row">
                <div *ngFor="let item of blogs_arr" class="col-12 col-md-4 mb-4">
                    <div class="cardStyle" (click)="blogDetails(item.id)">
                        <div class="imageBlog">
                            <img src="{{item.media[0].image_url}}" class="img-fluid"
                                (error)="item.media[0].image_url = 'assets/images/rk.jpeg'">
                        </div>
                        <div class="info">
                            <p>{{item.title}}</p>
                            <p>{{item.joined_date  | date:'mediumDate'}}</p>
                            <p class="readMore d-flex">
                                <a>Read More</a>
                                <img style="width: 15px; margin-top: 4px;" src="assets/images/arrowRigth.png">
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</section>
