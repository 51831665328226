import { Component, OnInit } from '@angular/core';
import { RestService } from 'src/app/services/rest.service';
import { MatDialog } from '@angular/material/dialog';
import { UpdataClientComponent } from '../updata-client/updata-client.component';
import { UpdateMoreInfoComponent } from '../update-more-info/update-more-info.component';
import { CancelConfirmComponent } from '../cancel-confirm/cancel-confirm.component';
import { environment } from 'src/environments/environment';
import { EditOrderComponent } from '../edit-order/edit-order.component';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss'],
})
export class UserProfileComponent implements OnInit {
  token: any;
  clientID: any;
  fullName: any;
  mobileNumber: any;
  city: any;
  email: any;
  userName: any;
  best_surprise: any;
  favColor: any;
  marital_status: any;
  Ultimate_goals: any;
  orders: any = [];
  order_request: any = [];
  constructor(private rest: RestService, public dialog: MatDialog) {}

  ngOnInit() {
    this.token = localStorage.getItem('token');
    this.clientID = localStorage.getItem('clientID');

    this.getData();
  }

  getData() {
    this.rest.userData(this.clientID, this.token).subscribe((res: any) => {
      if (res.first_name) {
        this.fullName = res.first_name + ' ' + res.last_name;
      } else {
        this.fullName = ' ';
      }
      this.mobileNumber = res.phone_number ? res.phone_number : 'No Data Found';

      this.city = res.city == null ? 'No Data Found' : res.city;

      this.email = res.email;
      this.userName = res.username;
      this.best_surprise = res[
        `do you like surprises, if yes, what's your best surprise`
      ]
        ? res[`do you like surprises, if yes, what's your best surprise`]
        : 'No Data Found';
      this.favColor = res['favorite colour']
        ? res['favorite colour']
        : 'No Data Found';
      this.marital_status = res['marital status']
        ? res['marital status']
        : 'No Data Found';
      this.Ultimate_goals = res['your ultimate goal']
        ? res['your ultimate goal']
        : 'No Data Found';
      this.orders = res.orders;

      res.orders.forEach((element: any) => {
        element.OrdersProducts.forEach((item: any) => {
          if (item.ordersproducts.images.length != 0) {
            item.ordersproducts.images[0].image = `${environment.apiUrl}/images/?id=${item.ordersproducts.images[0].image_url}`;
          } else {
            item.ordersproducts.images[0] = {
              image: 'assets/images/rk.jpeg',
            };
          }
        });
      });

      res.requests.forEach((element: any) => {
        if (element.images[0]) {
          // element.images[0].image = this._sanitizer.bypassSecurityTrustUrl(`data:image/png;base64,`+ element.images[0].image)
          element.images[0].image = `${environment.apiUrl}/images/?id=${element.images[0].image_url}`;
        }
      });

      this.order_request = res?.requests;
    });
  }

  // client_finsh_data() {
  //   let obj = {
  //     id: this.clientID,
  //     'marital status': 'edit email for user',
  //     job: '',
  //     'favorite colour': 'wawa',
  //     'how did you know us': 'koko',
  //     'your ultimate goal': 'wara safi7et l zbala',
  //     'how you make yourself happy': 'safi7et l zbala',
  //     'how do you spend your spare time': 'l zbala nfsaha',
  //     "do you like surprises, if yes, what's your best surprise":
  //       'l tot abo sharon',
  //   };
  //   this.rest.finishClientData(obj, this.token).subscribe((res: any) => {});
  // }

  editMoreInfo() {
    const dialogRef = this.dialog.open(UpdateMoreInfoComponent, {
      width: '500px',
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        result.id = this.clientID;
        this.rest.finishClientData(result, this.token).subscribe((res: any) => {
          this.getData();
        });
      }
    });
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(UpdataClientComponent, {
      width: '500px',
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        let results: any = result;
        results.id = this.clientID;
        this.rest
          .updateClientData(results, this.token)
          .subscribe((res: any) => {
            this.getData();
          });
      }
    });
  }

  openDialogCancel(order_id: any): void {
    const dialogRef = this.dialog.open(CancelConfirmComponent, {
      width: '500px',
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result == 'true') {
        this.rest
          .cancelOrder(this.token, order_id)
          .subscribe((res: any) => {
            this.rest.successToast('Order canceled');
            this.getData();
          });
      }
    });
  }

  openDialogEdit(order_id: any): void {
    const dialogRef = this.dialog.open(EditOrderComponent, {
      width: '700px',
      height: '70%',
      data: {
        orderId: order_id,
        token: this.token,
      },
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.getData();
      }
    });
  }
}
