import { Component, OnInit } from '@angular/core';
import {
  FormGroup,
  FormControl,
  Validators,
  AbstractControl,
  ValidationErrors,
} from '@angular/forms';
import { RestService } from 'src/app/services/rest.service';
import { Router } from '@angular/router';
import {
  SocialAuthService,
  FacebookLoginProvider,
  SocialUser,
} from 'angularx-social-login';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  constructor(
    private rest: RestService,
    private socialAuthService: SocialAuthService,
    private route: Router
  ) {}

  loginForm: FormGroup | any;
  socialUser!: SocialUser;
  ngOnInit() {
    //Login Form
    this.loginForm = new FormGroup({
      emailOrPhone: new FormControl('', [
        Validators.required,
        this.emailOrPhoneValidator,
      ]),
      password: new FormControl('', [Validators.required]),
    });
    //Login Form

    this.rest.getSignOut().subscribe((res: any) => {
      location.reload();
    });

    this.socialAuthService.authState.subscribe((user: any) => {
      if (user) {
        let obj = {
          firstName: user.firstName,
          lastName: user.lastName,
          email: user.email,
        };
        this.rest.loginFB(obj).subscribe((res: any) => {
          localStorage.setItem('token', res.access_token);
          localStorage.setItem('user', res.client.email);
          localStorage.setItem('phone_number', res.client.phone_number);
          localStorage.setItem('clientID', res.client.id);
          this.rest.successToast('Login Successfully');
          this.rest.setLoginStatus(true);
          this.rest.SendDataIfLogin('true');
          this.route.navigateByUrl('/home');
        });
      }
    });
  }

  login() {
    let form;
    if (this.loginForm.get('emailOrPhone').value.includes('@')) {
      form = {
        email: this.loginForm.get('emailOrPhone').value,
        password: this.loginForm.get('password').value,
      };
    } else {
      form = {
        phone_number: this.loginForm.get('emailOrPhone').value,
        password: this.loginForm.get('password').value,
      };
    }
    // let form = this.loginForm.value;

    if (
      this.loginForm.get('emailOrPhone').valid &&
      this.loginForm.get('password').valid
    ) {
      this.rest.login(form).subscribe(
        (res: any) => {
          localStorage.setItem('token', res.access_token);
          localStorage.setItem('user', res.user.email);
          localStorage.setItem('phone_number', res.user.phone_number);
          localStorage.setItem('clientID', res.user.id);
          this.rest.successToast('Login Successfully');
          this.rest.setLoginStatus(true);
          this.rest.SendDataIfLogin('true');
          this.route.navigateByUrl('/home');
        },
        (err: any) => {
          this.rest.errorToaster('Please check your Email / Phone Or Password');
        }
      );
    }
  }

  loginWithFacebook(): void {
    this.socialAuthService
      .signIn(FacebookLoginProvider.PROVIDER_ID)
      .then((user) => {
        // this.socialLogin(user, SocialProvidersEnum.Facebook);
      })
      .catch((err) => {});
  }

  signOut(): void {
    this.socialAuthService.signOut().then((res) => {});
  }

  emailOrPhoneValidator(control: AbstractControl): ValidationErrors | null {
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    const phonePattern = /^[0-9]{11}$/;

    const value = control.value;

    if (!value) {
      return null; // Allow empty value
    }

    if (emailPattern.test(value) || phonePattern.test(value)) {
      return null; // Validation passes
    } else {
      return { invalidEmailOrPhone: true }; // Validation fails
    }
  }
}
