import { Component, OnInit } from '@angular/core';
import { RestService } from 'src/app/services/rest.service';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.scss'],
})
export class CategoryComponent implements OnInit {
  all_category: any = [];
  constructor(
    private rest: RestService,
    private router: Router,
    private _sanitizer: DomSanitizer
  ) {}

  ngOnInit() {
    this.all_cat();
  }

  all_cat() {
    this.rest.allCat().subscribe((res: any) => {
      this.all_category = res;
      this.all_category.forEach((element: any) => {
        if (element.image != null) {
          element.image.image_url = `${environment.apiUrl}/images/?id=${element.image.image_url}`;
        } else {
          element.image = {
            image_url: 'assets/images/rk.jpeg',
          };
        }
      });
    });
  }

  cat_details(value: any) {
    localStorage.setItem('catName', value.name);
    this.router.navigate(['/Products', value.id]);
  }
}
