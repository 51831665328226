import { Component, OnInit } from '@angular/core';
import { RestService } from 'src/app/services/rest.service';
import { ChooseAriaComponent } from '../choose-aria/choose-aria.component';
import { MatDialog } from '@angular/material/dialog';
import { AddresComponent } from '../addres/addres.component';
import { Router } from '@angular/router';
import { AddresInfoComponent } from '../addres-info/addres-info.component';
import { environment } from 'src/environments/environment';

import {
  AbstractControl,
  FormControl,
  FormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';

@Component({
  selector: 'app-shop-cart',
  templateUrl: './shop-cart.component.html',
  styleUrls: ['./shop-cart.component.scss'],
})
export class ShopCartComponent implements OnInit {
  cart: any;
  showMessage = true;
  totalPrice = 0;
  oldTotalPrice = 0;
  token: any;
  totalPriceForPackage = 0;
  clientID: any;
  arrOfProd: any = [];
  arrOfPackages: any = [];
  clientObj: any;
  addressId: any;
  cashed = true;
  paymentMethodSelected: boolean = false;

  showAddingNewAddressForm: boolean = false;
  userAddresses: any;
  clientId: any;
  addressIdSelected: any;
  clientAddress: FormGroup | any;
  addressSelected: boolean = false;

  zeroQuantity: boolean = false;

  promoCode: any;
  apiUrl = `${environment.apiUrl}/images/?id=`;

  constructor(
    private rest: RestService,
    public dialog: MatDialog,
    private route: Router
  ) {}

  ngOnInit() {
    this.getClientDataFromLocalStorage();
    this.phoneNumberForm();
    this.clientId = localStorage.getItem('clientID');
    this.getClientAddressForm();
    this.getClientAddress();
    //  this.openAria()
    this.token = localStorage.getItem('token');
    this.cart = localStorage.getItem('cart');
    this.cart = JSON.parse(this.cart);
    if (this.cart) {
      this.showMessage = false;
      this.cart.forEach((element: any) => {
        if (element?.quantity <= 0) {
          this.zeroQuantity = true;
        }

        if (element?.count) {
          this.oldTotalPrice =
            this.totalPrice + element?.price * element?.count;
          this.totalPrice = this.totalPrice + element?.price * element?.count;
        } else {
          this.oldTotalPrice =
            this.totalPrice + element?.total_price * element?.amount;
          this.totalPrice =
            this.totalPrice + element?.total_price * element?.amount;
        }

        if (element.count) {
          element.imagePath = element.images[0].image_url;
        } else {
          if (element.image == null) {
            element.image = 'assets/images/rk.jpeg';
          }
        }
      });
    } else {
      this.getCart();
      // this.showMessage = true
    }
  }

  getClientDataFromLocalStorage() {
    this.token = localStorage.getItem('token');
    this.clientID = localStorage.getItem('clientID');
    this.getClientData();
    this.cart = localStorage.getItem('cart');
    this.cart = JSON.parse(this.cart);
    // this.fees = this.cart[0].fees;
    if (this.cart[0]?.promocode) {
      this.promoCode = this.cart[0].promocode;
    } else {
      this.promoCode = '';
    }
    this.cart.forEach((element: any) => {
      if (element.count) {
        this.arrOfProd.push({
          id: element.id,
          quantity: element.count,
        });
      }
      if (!element.count) {
        this.arrOfPackages.push({
          id: element.id,
          amount: element.amount,
        });
      }
    });
  }

  getClientData() {
    this.rest.userData(this.clientID, this.token).subscribe((res: any) => {
      this.clientObj = res;
    });
  }

  getCart() {
    let token = localStorage.getItem('token');
    this.rest.getCart(token).subscribe((res: any) => {
      if (res) {
        this.cart = res;
        localStorage.setItem('cart', JSON.stringify(res));
        this.rest.SendDataCard(true);
        this.cart.forEach((element: any) => {
          this.oldTotalPrice = element.total_price
            ? this.totalPrice +
              element.price * element.count +
              element.total_price
            : this.totalPrice + element.price * element.count;
          this.totalPrice = element.total_price
            ? this.totalPrice +
              element.price * element.count +
              element.total_price
            : this.totalPrice + element.price * element.count;
          if (element.count) {
            element.imagePath = element.images[0].image_url;
          } else {
            if (element.image == null) {
              element.image = 'assets/images/rk.jpeg';
            }
          }
        });
        this.showMessage = false;
      } else {
        this.showMessage = true;
      }
    });
  }

  removeItem(id: any) {
    this.totalPrice = 0;
    this.oldTotalPrice = 0;
    for (let i = 0; i < this.cart.length; i++) {
      if (this.cart[i].id == id) {
        this.cart.splice(i, 1);
      }
    }

    if (this.cart.length == 0) {
      localStorage.removeItem('cart');
      this.showMessage = true;
      this.rest.deleteCart(this.token).subscribe((res: any) => {});
      this.rest.SendDataCard(false);
    } else {
      this.cart.forEach((element: any) => {
        this.oldTotalPrice = this.totalPrice + element.price * element.count;
        this.totalPrice = this.totalPrice + element.price * element.count;
      });
      localStorage.setItem('cart', JSON.stringify(this.cart));
      this.rest.SendDataCard(true);
    }
  }
  removeAll() {
    localStorage.removeItem('cart');
    this.cart = [];
    this.showMessage = true;
    this.rest.SendDataCard(true);
    this.rest.deleteCart(this.token).subscribe((res: any) => {});
  }

  //Phone number Form
  phoneNoForm: FormGroup | any;
  phoneNumberForm() {
    this.phoneNoForm = new FormGroup({
      phoneNumber: new FormControl('', [
        Validators.required,
        this.phoneNumberValidator,
      ]),
    });
  }
  //Phone number Form

  promoName: any;
  showPromo: any = false;
  showNotValidPromo = false;
  disabledButton = false;
  fees: any = '';
  discountValue: any = 0;
  getValue(event: any) {
    // this.promoName = event.target.value;
    this.rest
      .getPromo(this.promoName, this.totalPrice, this.token)
      .subscribe((res: any) => {
        if (res.status == false) {
          this.showPromo = false;
          this.showNotValidPromo = true;
        } else {
          this.discountValue = this.totalPrice - res;
          this.totalPrice = res;
          this.showPromo = true;
          this.disabledButton = true;
          this.showNotValidPromo = false;
          this.cart[0].promocode = this.promoName;
          localStorage.setItem('cart', JSON.stringify(this.cart));
        }
      });
  }

  changeData(event: any) {
    this.promoCode = event.target.value;
    this.promoName = event.target.value;
    if (event.target.value == '') {
      this.disabledButton = false;
      this.showPromo = false;
    }
  }
  openAria(): void {
    const dialogRef = this.dialog.open(ChooseAriaComponent, {
      width: '300px',
    });
    dialogRef.afterClosed().subscribe((result: any) => {});
  }

  showFees = false;
  changeAria(value: any) {
    // this.totalPrice = 0
    this.fees = value.value;
    this.fees = +this.fees;
    this.totalPrice = this.oldTotalPrice + this.fees;
    this.showFees = true;
    this.cart[0].fees = this.fees;
    localStorage.setItem('cart', JSON.stringify(this.cart));
  }

  paymentMethodValue(event: any) {
    this.paymentMethodSelected = true;
    this.cashed = event;
  }

  proceedToDelivery(): void {
    const dialogRef = this.dialog.open(AddresComponent, {
      width: '500px',
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.addressId = result;
        this.checkout();

        // let results = result;
        // results.id = this.clientID;
        // this.rest
        //   .updateClientData(results, this.token)
        //   .subscribe((res: any) => {
        //     this.checkout();
        //   });
      }
    });
  }

  openAddressInfo(): void {
    if (
      this.clientObj?.address == null ||
      this.clientObj?.city == null ||
      this.clientObj?.governorate == null
    ) {
      this.proceedToDelivery();
    } else {
      const dialogRef = this.dialog.open(AddresInfoComponent, {
        width: '500px',
        data: this.clientObj,
      });
      dialogRef.afterClosed().subscribe((result: any) => {
        if (result) {
          if (result == 'true') {
            this.checkout();
          } else {
            this.proceedToDelivery();
          }
        }
      });
    }
  }

  checkout() {
    let obj = {
      client_id: this.clientID,
      products: this.arrOfProd,
      cashed: this.cashed,
      packages: this.arrOfPackages,
      promocode: this.promoCode,
      fees: this.fees,
      address_id: this.addressId,
      phone_number: this.phoneNoForm.get('phoneNumber').value,
    };

    this.rest.addOrder(obj, this.token).subscribe(
      (res: any) => {
        this.rest.successToast('Order received');
        localStorage.removeItem('cart');
        this.rest.deleteCart(this.token).subscribe((res: any) => {});
        this.rest.SendDataCard('true');
        this.route.navigateByUrl('/home');
      },
      (err: any) => {
        this.rest.errorToaster('Something Wrong Pleas Try Again');
      }
    );
  }

  phoneNumberValidator(control: AbstractControl): ValidationErrors | null {
    const phonePattern = /^[0-9]{11}$/;
    const value = control.value;

    if (!value) {
      return null; // Allow empty value
    }

    if (phonePattern.test(value)) {
      return null; // Validation passes
    } else {
      return { invalidEmailOrPhone: true }; // Validation fails
    }
  }

  userInput: string = '';
  invalidInput: boolean = false;

  validateInput(event: KeyboardEvent): void {
    const inputChar = String.fromCharCode(event.charCode);
    if (!/^\d+$/.test(inputChar)) {
      event.preventDefault();
      this.invalidInput = true;
    } else {
      this.invalidInput = false;
    }
  }

  getClientAddress() {
    this.rest.getUserAddress(this.token, this.clientId).subscribe((res) => {
      this.userAddresses = res;
    });
  }

  selectAddress(addressId: any) {
    this.addressId = addressId;
    this.addressSelected = true;
  }

  addNewAddress() {
    this.showAddingNewAddressForm = true;
    this.clientAddress.get('address').reset();
    this.clientAddress.get('governorate').reset();
    this.clientAddress.get('city').reset();
  }

  saveAddress() {
    this.showAddingNewAddressForm = false;
    this.rest
      .addNewAddress(this.clientAddress.value, this.token)
      .subscribe(() => {
        this.rest.successToast('Address added successfully');
      });

    setTimeout(() => {
      this.getClientAddress();
    }, 100);
  }

  getClientAddressForm() {
    this.clientAddress = new FormGroup({
      address: new FormControl('', [Validators.required]),
      governorate: new FormControl('', [Validators.required]),
      city: new FormControl('', [Validators.required]),
      client_id: new FormControl(this.clientId),
      // phone_number: new FormControl('', [Validators.required]),
    });
  }

  backToAddressesList() {
    this.showAddingNewAddressForm = false;
  }
}
