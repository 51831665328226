<footer class="footer">
        <div class="container foo-top">
         <div class="logoDiv">
          <a routerLink="/" class="logo">
            <img src="assets/images/Logo ver 2.png" alt="" />
          </a>
         </div>
          <div class="row">
            <div class="col-md-2 col-sm-6">
              <div class="widget widget-navigation">
                <ul>

                  <li><a (click)="onActivate()" routerLinkActive="active" routerLink="/home">Home</a></li>
                  <li><a routerLinkActive="active" routerLink="/about">About</a></li>
                  <li><a routerLinkActive="active" routerLink="/categories">Categories</a></li>
                  <li><a routerLinkActive="active" routerLink="/packges">Packages</a></li>
                  <li><a routerLinkActive="active" routerLink="/shop">Shop</a></li>
                </ul>
              </div>
            </div>

            <div class="col-md-3 offset-md-1 col-sm-6">

              <div class="widget widget-social">
                <h4 class="widget-title">Online shopping</h4>
                <div class="social-media">
                  <a target="_blank" (mouseenter)="groupFb = !groupFb"
                  (mouseleave)="groupFb = !groupFb"

                   href="https://www.facebook.com/groups/648946182371633">
                   <span class="groupFb" *ngIf="groupFb">Facebook group</span>
                    <!-- <i class="ti-facebook"></i> -->
                    <img src="assets/images/social/face.png">
                  </a>

                  <a target="_blank" (mouseenter)="groupPage = !groupPage"
                  (mouseleave)="groupPage = !groupPage"
                  href="https://www.facebook.com/profile.php?id=100076438811752">
                  <span class="groupPage" *ngIf="groupPage">Facebook Page</span>
                    <!-- <i class="ti-facebook"></i> -->
                    <img src="assets/images/social/face.png">
                  </a>

                  <a target="_blank" href="https://www.instagram.com/radwakhallaf_reviews/">
                    <!-- <i class="ti-instagram"></i> -->
                    <img src="assets/images/social/insta.png">
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="container">
          <p class="copyright">Copyright © 2023 Rdawa khallaf</p>
        </div>
      </footer>
