import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RestService } from 'src/app/services/rest.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-packge-details',
  templateUrl: './packge-details.component.html',
  styleUrls: ['./packge-details.component.scss'],
})
export class PackgeDetailsComponent implements OnInit {
  packID: any;
  discount: any;
  packImage: any;
  name: any;
  products: any = [];
  price: any;
  quantity: any;
  response: any;
  token: any;

  constructor(
    private route: ActivatedRoute,
    private rest: RestService,
    private routing: Router
  ) {}

  ngOnInit() {
    this.token = localStorage.getItem('token');

    this.packID = this.route.snapshot.paramMap.get('id');
    this.getData();
  }

  getData() {
    this.rest.packDetails(this.packID).subscribe((res: any) => {
      this.response = res;
      this.response.amount = 1;
      this.discount = res.discount;
      this.name = res.name;
      this.price = res.total_price;
      this.quantity = res.quantities;
      this.packImage = res.image;
      if (this.packImage != null) {
        this.packImage = `${environment.apiUrl}/images/?id=${res.image}`;
        // this.packImage = this._sanitizer.bypassSecurityTrustUrl(`data:image/png;base64,`+ this.packImage);
      } else {
        this.packImage = 'assets/images/rk.jpeg';
      }
      res.ProductPackages.forEach((element: any) => {
        if (element.products.image.length != 0) {
          element.imagePath = `${environment.apiUrl}/images/?id=${element.products.image[0]}`;
        } else {
          element.imagePath = 'assets/images/rk.jpeg';
        }
      });
      this.products = res.ProductPackages;
    });
  }

  addToCart() {
    localStorage.getItem('token');
    if (localStorage.getItem('token')) {
      let carts: any = localStorage.getItem('cart');
      carts = JSON.parse(carts);

      if (carts) {
        const existingItem = carts.find(
          (cart: any) => cart?.id === this.response.id
        );
        if (existingItem) {
          existingItem.amount += 1;
        } else {
          carts.push(this.response);
        }
        localStorage.setItem('cart', JSON.stringify(carts));
        this.rest.successToast('Package Added in Your Cart');
        this.rest.postCART(carts, this.token).subscribe((res: any) => {});
      } else {
        let cart = [];
        cart.push(this.response);
        localStorage.setItem('cart', JSON.stringify(cart));
        this.rest.successToast('Package Added in Your Cart');
        this.rest.postCART(cart, this.token).subscribe((res: any) => {});
      }

      this.rest.SendDataCard(true);
    } else {
      this.routing.navigateByUrl('/login');
    }
  }
}
