import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subject } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class RestService {
  baseUrl = `${environment.apiUrl}`;
  private ifLogin = new Subject<any>();
  private card = new Subject<any>();
  private signOut = new Subject<any>();

  private loggedInStatus = JSON.parse(
    localStorage.getItem('loggedIn') || 'false'
  );

  constructor(private http: HttpClient, private toastr: ToastrService) {}

  // auth
  signUp(obj: any) {
    return this.http.post(`${this.baseUrl}/client/register`, obj);
  }

  login(obj: any) {
    return this.http.post(`${this.baseUrl}/client/login`, obj);
  }

  loginFB(obj: any) {
    return this.http.post(`${this.baseUrl}/client/social_register`, obj);
  }

  // home

  homePage() {
    return this.http.get(`${this.baseUrl}/products/home`);
  }

  // category

  allCat() {
    return this.http.get(`${this.baseUrl}/cats/`);
  }

  catDetails(page: number, limit: number, id: any) {
    let params = new HttpParams().set('id', id);
    const options = { params: params };
    return this.http.get(
      `${this.baseUrl}/cats/detailed/?page=${page}&limit=${limit}`,
      options
    );
  }

  categoryDetails(page: number, limit: number, id: any) {
    let params = new HttpParams().set('category_id', id);
    const options = { params: params };
    return this.http.get(
      `${this.baseUrl}/products/listProducts/?page=${page}&limit=${limit}`,
      options
    );
  }

  // products

  allProduct(page: number, limit: number) {
    return this.http.get(
      `${this.baseUrl}/products/all/?page=${page}&limit=${limit}`
    );
  }

  prodDetails(id: any) {
    return this.http.get(`${this.baseUrl}/products/?product_id=${id}`);
  }

  addOrder(obj: any, token: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    });
    return this.http.post(`${this.baseUrl}/orders/`, obj, { headers: headers });
  }

  // packages

  allPackage() {
    return this.http.get(`${this.baseUrl}/packages/`);
  }

  packDetails(id: any) {
    return this.http.get(`${this.baseUrl}/packages/detailed?package_id=${id}`);
  }

  // user profile

  userData(id: any, token: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    });
    return this.http.get(`${this.baseUrl}/client/?client_id=${id}`, {
      headers: headers,
    });
  }

  finishClientData(obj: any, token: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    });
    return this.http.post(`${this.baseUrl}/client/finishData`, obj, {
      headers: headers,
    });
  }

  updateClientData(obj: any, token: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    });
    return this.http.put(`${this.baseUrl}/client/`, obj, { headers: headers });
  }

  addRate(obj: any, token: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    });
    return this.http.post(`${this.baseUrl}/rating/`, obj, { headers: headers });
  }

  // toster

  successToast(name: any) {
    this.toastr.success(name, 'Great', {
      timeOut: 3000,
      //  extendedTimeOut: 0
      positionClass: 'toast-custom',
    });
  }

  errorToaster(name: any) {
    this.toastr.error(name, 'Ops', {
      timeOut: 3000,
    });
  }

  // forgetPW

  forget_pw(obj: any) {
    // const headers = new HttpHeaders({
    //   'Content-Type': 'application/json',
    //   'Authorization': `Bearer ${token}`
    // })
    return this.http.post(`${this.baseUrl}/client/forgot/`, obj);
  }

  resetPw(obj: any) {
    return this.http.post(`${this.baseUrl}/client/reset/`, obj);
  }

  // obser

  SendDataIfLogin(event: any) {
    this.ifLogin.next(event);
  }

  getDataIfLogin(): Observable<any> {
    return this.ifLogin.asObservable();
  }

  SendDataCard(event: any) {
    this.card.next(event);
  }

  getDataCard(): Observable<any> {
    return this.card.asObservable();
  }

  SendSignOut(event: any) {
    this.signOut.next(event);
  }

  getSignOut(): Observable<any> {
    return this.signOut.asObservable();
  }

  //

  LoginStatus() {
    return JSON.parse(localStorage.getItem('loggedIn') || 'false');
  }

  setLoginStatus(value: any) {
    this.loggedInStatus = value;
    localStorage.setItem('loggedIn', 'true');
  }

  // product request

  requestProduct(obj: any, token: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    });
    var myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${token}`);
    const formData = new FormData();
    formData.append('key', obj.image);
    formData.append('name', obj.name);
    formData.append('url', obj.url);

    var requestOptions: any = {
      method: 'POST',
      headers: myHeaders,
      body: formData,
      redirect: 'follow',
    };

    return fetch(`${this.baseUrl}/products/request`, requestOptions);
  }

  // search

  search(name: any) {
    return this.http.get(
      `${this.baseUrl}/products/search/?product_name=${name}`
    );
  }

  // promo code

  getPromo(name: any, total: any, token: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    });
    return this.http.get(
      `${this.baseUrl}/promos/deduct?promocode=${name}&ammount=${total}`,
      { headers: headers }
    );
  }

  // blogs

  getBlogs() {
    return this.http.get(`${this.baseUrl}/blogs/`);
  }

  blogDetails(id: any) {
    return this.http.get(`${this.baseUrl}/blogs/detailed?id=${id}`);
  }

  // cart

  getCart(token: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    });
    return this.http.get(`${this.baseUrl}/orders/cart`, { headers: headers });
  }

  postCART(obj: any, token: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    });
    return this.http.post(`${this.baseUrl}/orders/cart`, obj, {
      headers: headers,
    });
  }

  deleteCart(token: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    });
    return this.http.delete(`${this.baseUrl}/orders/cart`, {
      headers: headers,
    });
  }

  // orders

  cancelOrder(auth_token: any, id: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${auth_token}`,
    });

    return this.http.delete(`${this.baseUrl}/orders/?id=${id}`, {
      headers: headers,
    });
  }


  orderDetails(auth_token: any, id: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${auth_token}`,
    });
    let params = new HttpParams().set('order_id', id);

    const options = { params: params, headers: headers };
    return this.http.get(`${this.baseUrl}/orders/detailed`, options);
  }

  //User's addresses
  getUserAddress(auth_token: any, clientId: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${auth_token}`,
    });

    return this.http.get(
      `${this.baseUrl}/client/address?client_id=${clientId}`,
      {
        headers: headers,
      }
    );
  }
  //User's addresses

  //Adding new address for the user
  addNewAddress(obj: any, token: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    });

    return this.http.post(`${this.baseUrl}/client/address`, obj, {
      headers: headers,
    });
  }
  //Adding new address for the user

  listCatOnly(token: any) {
    // this.auth_token = localStorage.getItem('access_token');
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    });

    return this.http.get(`${this.baseUrl}/cats/listed`, { headers: headers });
  }

  // Editing order
  editOrder(token: any, obj: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    });
    return this.http.put(`${this.baseUrl}/orders/`, obj, {
      headers: headers,
    });
  }
  // Editing order
}
