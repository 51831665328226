<section>
  <div class="container">
    <div class="shopping">
      <div *ngIf="!showMessage">
        <h1>Shopping cart</h1>
      </div>
      <div *ngIf="!showMessage" class="text-right">
        <p title="Remove all items" (click)="removeAll()"><a>Remove all</a></p>
      </div>
    </div>

    <div *ngIf="!showMessage">
      <div class="row" *ngFor="let item of cart">
        <div class="col-12" *ngIf="item?.count">
          <div class="card_style">
            <div class="row">
              <div class="col productImage">
                <img src="{{ item?.imagePath }}" />
              </div>
              <div class="col">
                <p>{{ item?.name | truncate : 40 }}</p>
              </div>
              <div class="col productImage">
                <p>{{ item?.price }} LE</p>
              </div>
              <div class="col">
                <p>{{ item?.count }}</p>
              </div>
              <div class="col">
                <p>{{ item?.count * item?.price }} LE</p>
              </div>
              <div
                class="col d-flex justify-content-end"
                (click)="removeItem(item?.id)"
              >
                <img
                  title="Remove this item"
                  class="removeIcon"
                  src="../../../assets/images/remove.png"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <hr *ngIf="!showMessage" />
    <div *ngIf="!showMessage">
      <div class="row" *ngFor="let item of cart">
        <div class="col-12" *ngIf="!item?.count">
          <div class="card_style">
            <div class="row">
              <div class="col productImage">
                <img src="{{ apiUrl + item?.image }}" />
              </div>
              <div class="col">
                <p>{{ item?.name | truncate : 40 }}</p>
              </div>
              <div class="col productImage">
                <p>{{ item?.total_price }} LE</p>
              </div>
              <!-- <div class="col">
                <p>{{ item?.ProductPackages.length }}</p>
              </div> -->

              <div class="col">
                <p>{{ item?.amount }}</p>
              </div>
              <div class="col">
                <p>{{ item?.amount * item?.total_price }} LE</p>
              </div>
              <div
                class="col d-flex justify-content-end"
                (click)="removeItem(item?.id)"
              >
                <img
                  title="Remove this item"
                  class="removeIcon"
                  src="../../../assets/images/remove.png"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <strong
      *ngIf="zeroQuantity && !showMessage"
      class="d-flex justify-content-center"
      >Order will arrive between 15-20 business days</strong
    >

    <div class="options mt-4" *ngIf="!showMessage">
      <div>
        <mat-form-field appearance="outline">
          <mat-label>Please choose your region</mat-label>
          <mat-select required (selectionChange)="changeAria($event)">
            <mat-option value="50">
              <div class="custom-mat-option">
                <span>Cairo</span>
                <span>50 LE</span>
              </div>
            </mat-option>
            <mat-option value="60">
              <div class="custom-mat-option">
                <span>Alexandria</span>
                <span>60 LE</span>
              </div>
            </mat-option>
            <mat-option value="65">
              <div class="custom-mat-option">
                <span>Delta</span>
                <span>65 LE</span>
              </div>
            </mat-option>

            <mat-option value="75">
              <div class="custom-mat-option">
                <span>Canal</span>
                <span>75 LE</span>
              </div>
            </mat-option>

            <mat-option value="85">
              <div class="custom-mat-option">
                <span>North Coast & Upper Egypt</span>
                <span>85 LE</span>
              </div>
            </mat-option>

            <mat-option value="95">
              <div class="custom-mat-option">
                <span>Sinai & Red Sea</span>
                <span>95 LE</span>
              </div>
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>

    <div *ngIf="!showMessage" class="totalOrder">
      <p>
        Total Price : {{ totalPrice }} LE <br />
        <span *ngIf="!showFees">( Order does not include delivery fees )</span>
        <span *ngIf="showFees"
          >(Order Price {{ oldTotalPrice }} + Fees {{ fees }} )</span
        >
      </p>

      <div class="promoCode" [formGroup]="phoneNoForm">
        <div class="mainPromo">
          <div>
            <label>Phone number</label>
            <input
              formControlName="phoneNumber"
              type="text"
              class="form-control"
              appPreventSpaceDirective
              [(ngModel)]="userInput"
              (keypress)="validateInput($event)"
            />

            <span
              class="text-danger"
              *ngIf="
                phoneNoForm?.controls.phoneNumber.touched &&
                phoneNoForm?.controls.phoneNumber.errors?.required
              "
            >
              Phone number is required
            </span>
            <span
              class="text-danger"
              *ngIf="
                phoneNoForm.controls.phoneNumber.errors?.invalidEmailOrPhone
              "
            >
              Please enter a valid Phone number
            </span>
          </div>
        </div>
      </div>

      <!-- <div class="promoCode">
        <div class="mainPromo">
          <label>Promo Code</label>

          <div class="d-flex align-items-center justify-content-between">
            <div class="w-75">
              <input
                [(ngModel)]="promoName"
                (input)="changeData($event)"
                (keyup.enter)="getValue($event)"
                type="text"
                class="form-control promoCodeInput"
              />
            </div>
            <div class="d-flex justify-content-end">
              <button
                [disabled]="disabledButton"
                (click)="getValue($event)"
                class="btn m-0 btn-primary"
              >
                verify
              </button>
            </div>
          </div>

          <span *ngIf="showPromo" class="promocodeValid"
            >Promo code valid (Discount {{ discountValue }})</span
          >
          <span style="color: red" *ngIf="showNotValidPromo"
            >Promo code Not valid</span
          >
        </div>
      </div> -->
    </div>

    <div class="addressOption mt-4 mb-4" *ngIf="!showMessage">
      <h3>
        {{
          userAddresses?.length
            ? "Address"
            : "It seems you didn't provide your address"
        }}
      </h3>
      <hr />
      <div>
        <div *ngIf="!showAddingNewAddressForm">
          <div *ngFor="let address of userAddresses" class="addressesDiv">
            <input
              class="mr-2"
              type="radio"
              id="radio_{{ address.id }}"
              [value]="address?.id"
              name="address"
              (change)="selectAddress(address?.id)"
            />
            <label for="radio_{{ address.id }}">
              <div class="d-flex align-items-center">
                <strong
                  >{{ address?.city
                  }}<small class="ml-2">{{
                    address?.governorate
                  }}</small></strong
                >
              </div>
              <p>{{ address?.addresss }}</p></label
            ><br />
          </div>
        </div>

        <form [formGroup]="clientAddress" *ngIf="showAddingNewAddressForm">
          <div class="form-group">
            <label>City</label>
            <input formControlName="city" type="text" class="form-control" />
          </div>
          <div class="form-group">
            <label>Governorate</label>
            <input
              formControlName="governorate"
              type="text"
              class="form-control"
            />
          </div>
          <div class="form-group">
            <label>Address</label>
            <input formControlName="address" type="text" class="form-control" />
          </div>
        </form>

        <div class="d-flex justify-content-between addressActions">
          <button
            *ngIf="!showAddingNewAddressForm"
            (click)="addNewAddress()"
            class="btn w-100"
          >
            Add new address
          </button>

          <button
            [disabled]="!clientAddress.valid"
            *ngIf="showAddingNewAddressForm"
            (click)="saveAddress()"
            class="saveAndBack"
          >
            Save
          </button>

          <button
            *ngIf="showAddingNewAddressForm"
            (click)="backToAddressesList()"
            class="saveAndBack"
          >
            Back
          </button>
        </div>

        <!-- <div class="d-flex justify-content-between addressActions mt-3">
          <button
            [disabled]="
              !addressIdSelected ||
              addressIdSelected == undefined ||
              showAddingNewAddressForm
            "
            class="btn confirmOrder w-100"
          >
            Confirm Order
          </button>
        </div> -->
      </div>
    </div>

    <div *ngIf="!showMessage" class="totalOrder">
      <div class="promoCode">
        <div class="mainPromo">
          <label>Promo Code</label>

          <div class="d-flex align-items-center justify-content-between">
            <div class="w-50">
              <input
                [(ngModel)]="promoName"
                (input)="changeData($event)"
                (keyup.enter)="getValue($event)"
                type="text"
                class="form-control promoCodeInput"
              />
            </div>
            <div class="d-flex justify-content-end">
              <button
                [disabled]="disabledButton"
                (click)="getValue($event)"
                class="btn m-0 btn-primary"
              >
                verify
              </button>
            </div>
          </div>

          <span *ngIf="showPromo" class="promocodeValid"
            >Promo code valid (Discount {{ discountValue }})</span
          >
          <span style="color: red" *ngIf="showNotValidPromo"
            >Promo code Not valid</span
          >
        </div>
      </div>
    </div>

    <div class="options mt-4" *ngIf="!showMessage">
      <div>
        <form>
          <p>Please select your payment method:</p>
          <div class="w-100 d-flex align-items-center justify-content-between">
            <div>
              <input
                class="mr-2"
                type="radio"
                id="cashOnDelivery"
                name="payment_method"
                value="cashOnDelivery"
                (change)="paymentMethodValue(true)"
              />
              <label for="cashOnDelivery">Cash on delivery</label>
            </div>

            <div>
              <input
                class="mr-2"
                type="radio"
                id="vodafoneCash"
                name="payment_method"
                value="vodafoneCash"
                (change)="paymentMethodValue(false)"
              />
              <label for="vodafoneCash">Vodafone cash</label>
            </div>
          </div>
        </form>
      </div>
    </div>

    <div *ngIf="showMessage">
      <img src="../../../assets/images/cart.png" class="emptyCart" />
      <br />

      <h1 class="d-flex justify-content-center">Your shop cart is empty</h1>
    </div>

    <div *ngIf="!showMessage" class="text-center Checkout">
      <button
        [disabled]="
          fees == '' ||
          !paymentMethodSelected ||
          phoneNoForm?.invalid ||
          !addressSelected
        "
        class="btn confirmOrder"
        (click)="checkout()"
      >
        Confirm Order
      </button>
    </div>
    <div *ngIf="showMessage" class="text-center Checkout">
      <button class="btn" routerLink="/shop">Shop Now</button>
    </div>
  </div>
</section>
