<section>
    <div class="container">
        <div class="title text-center">
            <h1>{{title}}</h1>
            <p>POSTED ON <span>{{date | date:'mediumDate'}}</span></p>
        </div>
        <div class="mediaImage">
            <img src="{{mainImage}}">
        </div>

        <br>

        <!-- {{content}} -->

        <!-- <h2>Content</h2> -->
        <p>
            {{content}}
        </p>

        <br>

        <!-- {{steps}}-->

        <!-- <h2 class="mt-4">Steps</h2> -->
        <p>
            {{steps}}
        </p>


        <h2 class="mt-4">Products</h2>

        <div class="row">
            <div class="col-6 col-md-3" *ngFor="let item of products">
                <div>
                    <img src="{{item.image[0].image_url}}">
                    <p class="text-center mt-2">
                        {{item.name}}
                    </p>
                </div>
            </div>
        </div>

        <!-- videos -->

        <br>
        <br>
        <hr>
        <div>

            <!-- <h2>Video</h2> -->
            <div class="row">
                <div class="col-12 col-md-6 text-center" *ngFor="let item of videosArr">
                    <video style="width: 100%" controls>
                        <source src="{{item.image_url}}" type="video/mp4">
                        <source src="{{item.image_url}}" type="video/ogg">
                    </video>
                </div>
            </div>
        </div>
    </div>
</section>