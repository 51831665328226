<section>
  <div class="container">
    <div class="row">
      <div class="col-12 col-md-6">
        <img [src]="packImage" alt="" />
      </div>
      <div class="col-12 col-md-6">
        <section class="main">
          <h2>{{ name }}</h2>
          <h2>{{ price }} LE</h2>
        </section>
        <h4>Discount {{ discount }}</h4>
      </div>
    </div>

    <div class="row">
      <div class="col-12 col-md-4" *ngFor="let item of products">
        <div class="product">
          <h3>{{ item.products.name }}</h3>
          <div>
            <p>Amount ({{ item.amount }})</p>
            <img [src]="item.imagePath" />
          </div>
        </div>
      </div>
    </div>

    <button class="btn addOrder" (click)="addToCart()">Add To Cart</button>
  </div>
</section>
