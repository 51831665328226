<section>
    <div>
        <form [formGroup]="updateClient">
            <div class="form-group">
                <label>First Name</label>
                <input formControlName="first_name" type="text" class="form-control">
            </div>
            <div class="form-group">
                <label>Last Name</label>
                <input formControlName="last_name" type="text" class="form-control">
            </div>
            <div class="form-group">
                <label>Phone Number</label>
                <input formControlName="phone_number" type="text" class="form-control">
            </div>
            <div class="form-group">
                <label>City</label>
                <input  formControlName="city" type="text" class="form-control">
            </div>
            <div class="form-group">
                <label>Address</label>
                <input  formControlName="address" type="text" class="form-control">
            </div>
            <div class="form-group">
                <label>Governorate</label>
                <input  formControlName="governorate" type="text" class="form-control">
            </div>
            <button (click)="onNoClick()" class="btn">Save</button>
        </form>
    </div>
</section>